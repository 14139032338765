import React, { useEffect, useState } from "react";
import { TouchableOpacity, ScrollView } from "react-native-web";
import connect from "react-redux/es/connect/connect";

import {
  areRecordsTheSame,
  bestRecord,
  calculatePlaybackStateName,
  sizedUrl,
  slugForRecord,
  isPlaylist,
  randomImageUrl
} from "../../helpers";
import _ from "lodash";
import {
  next,
  playPause,
  prev,
  recordPlayPressed,
  recordSelected,
  startPreviewingRecord,
  stop,
  stopPreviewingRecord,
  transitionPreviewToPlay
} from "../../actions/playback-actions";
import {
  TURN_BIG_RECORD_TO_SIDE,
  TURN_RECORD_OVER
} from "../../reducers/record-reducer";
import {
  addRecord,
  clearSelectedRecord,
  deleteRecord,
  loadDetailedInfoForRecord,
  loadRecordIfNeeded,
  loadTracksIfNeeded
} from "../../actions/record-actions";
import styles from "./big-record-details.module.scss";
import moment from "moment";
import Track from "../Track/track.js";
import { reportEvent } from "../../analytics";
import { devMode, EndPoints, flag, Flags } from "../../helpers/flags";
import { isContainedInIosNativeApp } from "../../helpers/native-ios-mk-implementation";
import { withData } from "react-orbitjs";
import orbitStore from "../../orbit/orbit-coordinator";
var md5 = require("md5");

var classNames = require("classnames");

function BigRecordDetailsComponent(props) {
  const {
    klass = "default",
    showAppleMusicBlurb = true,
    domId,
    tracks,
    playing,
    isPlaying,
    availableImageWidth,
    animationClass,
    queue,
    libraryRecordsShelfIndex,
    recentsShelfIndex,
    recentlyAddedShelfIndex
  } = props;
  const [showApi, setShowApi] = useState(false);
  const [record, setRecord] = useState(props.record);
  const [labelName, setLabelName] = useState(record.attributes.recordLabel);
  const [releaseDate, setReleaseDate] = useState(
    `${record.attributes.releaseDate}*`
  );
  const [labelArea, setLabelArea] = useState(null);
  const [labelDates, setLabelDates] = useState("");
  const [width, setWidth] = useState(_.get(props.layoutRect, "width", 0));

  useEffect(() => {
    setRecord(props.record);
    props.loadTracksIfNeeded(props.record);
  }, [props.record.id]);
  useEffect(() => {
    setWidth(props.width);
  }, [props.width]);

  useEffect(() => {
    const detailedInfo = props.recordIdToDetailedInfoMap[record.id];
    if (detailedInfo && detailedInfo.earliest_release) {
      const _byArtistNameOnly = detailedInfo.by_artist_name_only;
      if (!_byArtistNameOnly) {
        setLabelName(detailedInfo.earliest_release.label_name);
        setReleaseDate(detailedInfo.earliest_release.release_date);
      }
      setLabelArea(detailedInfo.earliest_release.label_area);

      if (detailedInfo.earliest_release.label_begin_year) {
        let labelDates = `${detailedInfo.earliest_release.label_begin_year}-`;
        const labelDates2 = detailedInfo.earliest_release.label_end_year
          ? detailedInfo.earliest_release.label_end_year
          : "present";
        setLabelDates(`${labelDates}${labelDates2}`);
      }
    }
  }, [
    record.id,
    md5(props.recordIdToDetailedInfoMap[record.id] || ""),
    props.recordSlugsWithNoImages
  ]);

  function visiblePlayControlButtons(playing, isPlaying, queue) {
    const ret = {};
    if (!isPlaying) return { play: true };
    if (playing) {
      ret["pause"] = true;
    } else {
      ret["play"] = true;
    }
    const initialPosition = isContainedInIosNativeApp() ? 1 : 0;
    const queueLength = _.get(queue, "_items", []).length;
    if (queueLength > 0) {
      const position = queue._position;
      if (playing) {
        if (position > initialPosition) ret["prev"] = true;
        if (position < queueLength) ret["next"] = true;
      }
    }
    return ret;
  }

  const visibleButtons = visiblePlayControlButtons(playing, isPlaying, queue);

  const playStyle = visibleButtons["play"] ? {} : { display: "none" };
  const stopStyle = visibleButtons["stop"] ? {} : { display: "none" };
  const pauseStyle = visibleButtons["pause"] ? {} : { display: "none" };
  const prevStyle = visibleButtons["prev"] ? {} : { display: "none" };
  const nextStyle = visibleButtons["next"] ? {} : { display: "none" };
  const { attributes: attr } = record;

  function playPauseClicked(event) {
    event.stopPropagation();
    event.preventDefault();
    //props.recordSelected(record);
    //props.turnRecordOver(record.id, domId);
    if (props.isPreviewing) {
      props.transitionPreviewToPlay(record, null);
    } else if (props.isPlaying) {
      props.playPause(event);
    } else props.recordPlayPressed(event, props.record);
    return true;
  }

  function addRecord(event) {
    event.stopPropagation();
    event.preventDefault();
    props.addRecord(
      [libraryRecordsShelfIndex, recentsShelfIndex, recentlyAddedShelfIndex],
      record
    );
    return true;
  }

  function deleteRecord(event) {
    window.alert("Head to the official Music app to delete an album.");
    // event.stopPropagation();
    // event.preventDefault();
    // props.deleteRecord(props.recentsShelfIndex, record);
    // return true;
  }

  const year = releaseDate && moment(releaseDate, "YYYY-MM-DD").format("YYYY");
  const coverWidth = Math.max(150, Math.floor(width / 4));
  const imageWidthForUrl = Math.max(coverWidth, availableImageWidth);

  function isFrontImage(image) {
    return isImageOfType(image, "Front");
  }

  function isImageOfType(image, type) {
    return image.types.includes(type);
  }

  // useEffect(() => {
  //   const _turnedOver = domId === turnedOverDomId && record.id === turnedOverRecordId
  //   if (_turnedOver) {
  //     setClasses(classNames(styles[klass],styles.turnOut))
  //     setTimeout(()=>setTurnedOver(true),200)
  //   } else {
  //     setClasses(classNames(styles[klass],turnedOver && styles.turnIn))
  //     setTimeout(()=>setTurnedOver(false),200)
  //   }
  //
  // }, [turnedOverRecordId, turnedOverDomId])
  const hasCoverArt = _.get(props, "coverArt.images", []).length > 1;
  const targetWidth = Math.min(
    225,
    props.clientWidth / 5,
    props.clientHeight / 5
  );

  // no greater than clientWidth*0.5
  // no less than 250
  // max(250,clientWidth*0.5)
  const coverTargetWidth = Math.min(
    120,
    props.clientWidth / 3,
    props.clientHeight / 3
  );
  const gridTemplateColumns = `0px 1fr`;
  //const gridTemplateColumns = `${coverTargetWidth}px 1fr`;
  const coverStyle = {
    width: coverTargetWidth
  };
  let formattedReleaseDate = "";
  if (
    releaseDate &&
    !releaseDate.includes("undefined") &&
    !releaseDate.includes("null")
  ) {
    formattedReleaseDate = releaseDate.substring(0, 4);
    if (!releaseDate.includes("--"))
      formattedReleaseDate = moment(releaseDate, "YYYY-MM-DD").format(
        "MMMM D, YYYY"
      );
  }
  const imgHeightWidth = Math.min(
    props.clientHeight - 78,
    props.clientWidth - 14
  );

  const rawImageUrl =
    props.coverArtImage || _.get(record.attributes, "artwork.url");

  const directShareUrl = _.get(record.attributes, "url");

  const imageUrl =
    rawImageUrl && flag(Flags.useRandomRecordData)
      ? randomImageUrl(record)
      : sizedUrl(rawImageUrl, 250);

  const fullHeight = true; //isPlaylist(record);

  function copy(text) {
    var input = document.createElement("input");
    input.setAttribute("value", text);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    document.body.removeChild(input);
    window.alert(
      `The Apple Music URL for this record was copied to the clipboard:\n\n${text}`
    );
    return result;
  }
  const urlPrefix = devMode()
    ? "http://localhost:3001"
    : "https://shelffm.ngrok.io";

  const isAdded = props.addedRecords.filter(r => r.id === record.id).length > 0;
  return (
    <div
      className={classNames(styles[klass], animationClass)}
      style={{
        width: imgHeightWidth,
        height: fullHeight ? null : imgHeightWidth
      }}
    >
      <div>
        <TouchableOpacity
          onPress={!!imageUrl ? () => props.turnRecordOver() : null}
        >
          <div className={styles["cover-header"]}>
            <div className={styles["front-cover"]} style={coverStyle}>
              {imageUrl && (
                <img
                  width={coverTargetWidth}
                  onClick={() => {
                    props.selectCoverArt(record, null, domId);
                    props.turnRecordOver(record, domId);
                  }}
                  src={imageUrl}
                />
              )}
            </div>
            <div className={styles["big-meta-text"]}>
              <div className={styles["playback-state-name"]}>
                {isPlaying && props.playbackStateName}
              </div>

              <div className={styles["big-name"]}>{attr.name}</div>
              <div className={styles["big-artist-name"]}>{attr.artistName}</div>
              <div className={styles["big-artist-name"]}>
                <div className={styles["big-label-name"]}>
                  {labelName}
                  <div className={styles["big-label-area"]}>
                    {labelArea &&
                      labelArea !== "United States" &&
                      ` (${labelArea}, ${labelDates})`}
                  </div>
                </div>
                <div className={styles["big-release-date"]}>
                  {formattedReleaseDate}
                </div>
              </div>
            </div>
            {!isPlaylist(record) && (
              <div className={styles.cornerButtons}>
                <div className={styles.cornerButton}>
                  <TouchableOpacity
                    onPress={() =>
                      copy(directShareUrl)
                    }
                  >
                    <i className="fa fa-share" />
                  </TouchableOpacity>
                </div>
                {isAdded ? (
                  <div className={styles.cornerButton}>
                    <TouchableOpacity onPress={e => deleteRecord(e)}>
                      <i
                        className="fa fa-minus-circle"
                        style={{ opacity: 0.5 }}
                      />
                    </TouchableOpacity>
                  </div>
                ) : (
                  <div className={styles.cornerButton}>
                    <TouchableOpacity onPress={e => addRecord(e)}>
                      <i className="fa fa-plus-circle" />
                    </TouchableOpacity>
                  </div>
                )}
              </div>
            )}
          </div>
        </TouchableOpacity>

        <div className={styles["header"]}>
          <div className={styles["tracks"]}>
            {tracks &&
              _.slice(tracks, 0, 400).map((track, i) => (
                <Track index={i} record={record} track={track} />
              ))}
          </div>
          {showAppleMusicBlurb && attr.editorialNotes && (
            <div className={styles.blurb}>
              <div className={styles.header}>from Apple Music</div>
              <div
                className={styles.standard}
                dangerouslySetInnerHTML={{
                  __html: attr.editorialNotes.standard
                }}
              />
            </div>
          )}
        </div>
        <TouchableOpacity onPress={() => setShowApi(b => !b)}>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: 8,
              opacity: 0.2,
              width: 50,
              height: 20
            }}
          />
        </TouchableOpacity>
        <div style={{ display: showApi ? "block" : "none" }}>
          <pre>
            {JSON.stringify(
              { ...record, coverArtImage: props.coverArtImage },
              null,
              2
            )}
          </pre>

          <div>
            <a href={props.lastApiCall.url}>api: {props.lastApiCall.url}</a>
            <pre>{JSON.stringify(props.lastApiCall.json, null, 2)}</pre>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  let { record } = ownProps;
  record = bestRecord(state, record) || record;
  const PlaybackStates = _.get(window, "MusicKit.PlaybackStates");

  const musicbrainzId = _.get(record, "attributes.musicbrainzId");
  const {
    recentsShelfIndex,
    recentlyAddedShelfIndex,
    libraryRecordsShelfIndex
  } = state.record;
  const addedRecords = [
    ...(state.record.shelves[libraryRecordsShelfIndex].records || []),
    ...(state.record.shelves[recentlyAddedShelfIndex].records || [])
  ];
  const rating = state.record.ratingsMap[slugForRecord(record, true)];
  const isAdded =
    rating === -1
      ? false
      : addedRecords.reduce(
          (acc, addedRecord) => areRecordsTheSame(record, addedRecord) || acc,
          false
        );

  const selectedRecord = _.get(state, "record.selected.record");
  const isSelected = areRecordsTheSame(record, selectedRecord);
  const coverArtImage = state.record.recordIdToCoverArtImageMap[record.id];

  return {
    coverArtImage,
    libraryRecordsShelfIndex,
    recentlyAddedShelfIndex,
    selectedRecord,
    isSelected,
    lastApiCall:
      state.api.calls.length > 0 && state.api.calls[state.api.calls.length - 1],
    bigRecordTurnedOver: state.record.bigRecordTurnedOver,
    turnedOverRecordId: _.get(state.record.turnedOverRecord, "recordId"),
    turnedOverDomId: _.get(state.record.turnedOverRecord, "domId"),
    recordIdToDetailedInfoMap: state.record.recordIdToDetailedInfoMap || {},
    recordSlugsWithNoImages: state.record.recordSlugsWithNoImages || {},
    coverArt: state.record.recordIdToCoverArtMetaDataMap[record.id],
    recentsShelfIndex,
    isAdded,
    tracks: state.record.recordIdToTracksMap[record.id],
    turnedOver: state.record.turnedOverRecordId === record.id,
    rating,
    musicbrainzOnly:
      musicbrainzId && state.record.mbidToMusicbrainzOnlyMap[musicbrainzId],
    palette: state.ui.palette,
    queue: state.playback.queue,
    record,
    playing:
      state.playback.playbackState === PlaybackStates["playing"] ||
      state.playback.nativePlaybackState === 1,

    isPlaying: _.get(state, "playback.currentlyPlayingRecord.id") === record.id,
    isPreviewing:
      record.id &&
      _.get(state, "playback.currentlyPreviewingRecord.id") === record.id,
    playbackStateName: calculatePlaybackStateName(state),
    previewStatus: state.playback.previewStatus,
    clientHeight: state.ui.documentElement.clientHeight,
    clientWidth: state.ui.documentElement.clientWidth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dismiss: () => {
      dispatch({ type: TURN_BIG_RECORD_TO_SIDE, side: 0 });
      setTimeout(() => dispatch(clearSelectedRecord()), 50);
    },
    transitionPreviewToPlay: (record, layoutRect) =>
      dispatch(transitionPreviewToPlay(record, layoutRect)),
    startPreviewingRecord: record => dispatch(startPreviewingRecord(record)),
    stopPreviewingRecord: record => dispatch(stopPreviewingRecord(record)),
    turnRecordOver: () => {
      dispatch({ type: TURN_BIG_RECORD_TO_SIDE, side: 0 });
    },
    selectCoverArt: (record, image, domId) => {
      dispatch({ type: "SELECT_COVER_ART", record, image, domId });

      dispatch({ type: TURN_RECORD_OVER, record, domId });
    },
    loadRecordIfNeeded: record => {
      dispatch(loadRecordIfNeeded(record));
    },
    loadTracksIfNeeded: record => {
      dispatch(loadTracksIfNeeded(record));
    },
    deleteRecord: (shelfIndex, record) => {
      dispatch({ type: TURN_BIG_RECORD_TO_SIDE, side: 0 });
      setTimeout(() => dispatch(clearSelectedRecord()), 50);
      dispatch(deleteRecord(shelfIndex, record));
    },
    addRecord: (shelfIndexes, record) => {
      reportEvent("record added");
      dispatch(addRecord(shelfIndexes, record));
    },
    recordSelected: (
      record,
      layoutRect,
      turnOverInitially = false,
      domId = null
    ) => {
      dispatch(recordSelected(record, layoutRect, turnOverInitially, domId));
    },
    clearSelectedRecord: () => {
      dispatch(clearSelectedRecord());
    },
    prev: e => {
      e.stopPropagation();
      dispatch(prev());
    },
    next: e => {
      e.stopPropagation();
      dispatch(next());
    },
    stop: e => {
      e.stopPropagation();
      dispatch(stop());
    },
    playPause: e => {
      e.stopPropagation();
      dispatch(playPause());
    },
    loadDetailedInfoForRecord: (record, num) =>
      dispatch(loadDetailedInfoForRecord(record, num)),
    recordPlayPressed: (event, record) => {
      event.stopPropagation();
      dispatch(recordPlayPressed(record));
    }
  };
};

const mapRecordsToProps = (state, ownProps) => {
  return {
    addedRecords: q =>
      q
        .findRecords("album")
        .filter({ attribute: "source", value: EndPoints.recentlyAdded })
  };
};

const BigRecordDetails = withData(mapRecordsToProps)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BigRecordDetailsComponent)
);

export default BigRecordDetails;
