import _ from "lodash";
import { defaultDiacriticsRemovalMap } from "./helpers/diacritics";
import { store } from "./index";
import { isContainedInIosNativeApp } from "./helpers/native-ios-mk-implementation";
import NativeIosMkImplementation from "./helpers/native-ios-mk-implementation";
import { NativePlaybackStateNames } from "./reducers/playback-reducer";
import {developerToken} from "./developer-token";
export const MUSICBRAINZ = "musicbrainz";
export const APPLE_MUSIC = "apple music";
export const uiVariables = {
  turnOverDuration: { value: 100, units: "ms" },
  turnOverPerspective: { value: 1200, units: "px" }
};

export const BASE_URL = "https://api.music.apple.com/v1/";
let debounceTimeout = null;

export const zIndices = {
  zero: 0,
  home: 10,
  selection: 20,
  highest: 30
};

for (let [key, value] of Object.entries(zIndices)) {
  document.documentElement.style.setProperty(`--z-${key}`, value);
}

export function browserCountryCode(def = "us") {
  const lang = window.navigator.language;
  const toks = _.split(lang, /[-_]/);
  if (toks.length === 2) return toks[1];
  else return def;
}

export function setCssVariable(k, v) {
  document.documentElement.style.setProperty(`--${k}`, v);
}
export async function postToSlack(body_text) {
  const webhook =
    "https://hooks.slack.com/services/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";
  try {
    const headers = {
      "Content-Type": "application/json",
      Origin: "https://shelf.fm",
      redirect: "follow",
      mode: "cors",
      "Access-Control-Allow-Origin": "*"
    };
    const response = await fetch(webhook, {
      method: "POST",
      headers,
      body: JSON.stringify({ body_text })
    });
    console.log("slack response", response);
  } catch (err) {
    console.log("slack err", err);
  }
}

export function calculatePlaybackStateName(state) {
  let playbackStateName = isContainedInIosNativeApp()
    ? NativePlaybackStateNames[state.playback.nativePlaybackState]
    : state.playback.playbackStateName;
  // if (["seeking", "stopped"].includes(playbackStateName))
  //   playbackStateName = "";
  // if (playbackStateName === "stalled") playbackStateName = "paused";
  return playbackStateName;
}

export const vecadd = (a, b) => {
  return [a[0] + b[0], a[1] + b[1]];
};
export const vecmult = (a, b) => {
  return [a[0] * b[0], a[1] * b[1]];
};
export const vecsub = (a, b) => {
  return [a[0] - b[0], a[1] - b[1]];
};

// function to keep anyone from mutating it by mistake
const zerovec = () => ({ x: 0, y: 0 });

const debounceTimeouts = {};

export function debounce(tag, fn, interval = 1000) {
  // if there is an existing timer, cancel it and create a new one
  if (debounceTimeouts[tag]) {
    clearTimeout(debounceTimeouts[tag]);
    debounceTimeouts[tag] = null;
  }
  debounceTimeouts[tag] = setTimeout(fn, interval);
}

export function cleanName(name) {
  // strip anything in parens
  const toks = _.split(name, /[(-]/);
  return toks[0].trim();
}

export function mk() {
  try {
    if (isContainedInIosNativeApp()) {
      return NativeIosMkImplementation.getInstance();
    } else {
      return window.MusicKit.getInstance();
    }
  } catch (err) {
    console.log("mk - err", err);
    return null;
  }
}

export function mkapi() {
  if (isContainedInIosNativeApp()) {
    NativeIosMkImplementation.getInstance();
    return NativeIosMkImplementation.api;
  } else {
    return mk().api;
  }
}

export function mkplayer() {
  const _mk = mk();
  if (!_mk) return null;
  if (isContainedInIosNativeApp()) {
    NativeIosMkImplementation.getInstance();
    return NativeIosMkImplementation.player;
  } else {
    return _mk;
  }
}

export function bestIdForRecord(record) {
  return record.catalogAlbumId || record.id;
}

export function slugForRecord(record, clean = false) {
  if (!record) return null;
  return slugForArtistNameAndAlbumName(
    record.attributes.artistName,
    clean ? cleanName(record.attributes.name) : record.attributes.name
  ); // + `-${record.attributes.recordLabel}`
}

export function dedupeRecords(records) {
  const slugs = {};
  return records.filter(record => {
    const slug = slugForRecord(record, true);
    if (slugs[slug]) return false;
    else {
      slugs[slug] = true;
      return true;
    }
  });
}

export function isMobile() {
  return "ontouchstart" in document.documentElement;
}

export function isMobileWeb() {
  return isMobile() && !isContainedInIosNativeApp();
}

export function combineRecords(record1, record2) {
  if (!record1) return record2;
  if (!record2) return record1;
  const newAttributes = { ...record1.attributes, ...record2.attributes };
  const newExtra = { ...record1.extra, ...record2.extra };
  const record3 = { ...record1 };
  record3.attributes = newAttributes;
  record3.extra = newExtra;
  return record3;
}

export function randomizeAttributes(record) {
  var number = 0;

  for (var i = 0; i < record.attributes.name.length; i++) {
    number = number + record.attributes.name.charCodeAt(i);
  }
  const n = (number % 19) + 1;

  const attributes = [
    {
      artistName: "Loaded Diaper",
      name: "Diaper Overload"
    },
    {
      artistName: "Fergus The Frog",
      name: "Tiger Lily"
    },
    {
      artistName: "D. Hovey / Granera",
      name: "Escalante"
    },
    {
      artistName: "Pizza",
      name: "Pasta"
    },
    {
      artistName: "Jill Jangle and the Cans",
      name: "Bang On!"
    },
    {
      artistName: "Elbow Bros.",
      name: "Back Alley"
    },
    {
      artistName: "The Haunted House",
      name: "Scary Story"
    },
    {
      artistName: "Ochre Rays",
      name: "Methylated"
    },
    {
      artistName: "Fred Limberger",
      name: "Yodelee"
    },
    {
      artistName: "The Baby Yodas",
      name: "Calvin The Dog"
    },
    {
      artistName: "Bastien",
      name: "Osmium"
    },
    {
      artistName: "Montana James",
      name: "Shimmer Shine"
    },
    {
      artistName: "The Snoopies",
      name: "Inside the Doghouse"
    },
    {
      artistName: "K7 bandits",
      name: "Chamay"
    },
    {
      artistName: "Felt Goblet",
      name: "re-action"
    },
    {
      artistName: "The Barking Dogs",
      name: "The Dog in the Yard"
    },
    {
      artistName: "Ray Shmur / Calidrone",
      name: "Live at the Royale"
    },
    {
      artistName: "Wise Eyez",
      name: "Livid"
    },
    {
      artistName: "The Kingdom Explorers",
      name: "Chaos at the Castle"
    },
    {
      artistName: "Family Activity",
      name: "Fun"
    }
  ];

  const _attributes = { ...record.attributes, ...attributes[n] };
  const record2 = { ...record };
  record2.attributes = _attributes;
  return record2;
}

export function pointInRect(x, y, rect) {
  if (!rect) return false;
  return (
    x > rect.x &&
    x < rect.x + rect.width &&
    y > rect.y &&
    y < rect.y + rect.height
  );
}

export function randomImageUrl(record) {
  var number = 0;

  for (var i = 0; i < record.attributes.name.length; i++) {
    number = number + record.attributes.name.charCodeAt(i);
  }
  //const n = (number % 19) + 1;
  const n = (record.index + 1) % 19;
  return `http://shelffm.ngrok.io/fake-covers/cover_-_${n}.jpeg`;
}

export function slugForArtistNameAndAlbumName(artistName, albumName) {
  return `${artistName}-${albumName}`;
}

export function isPlaylist(record) {
  return (
    record && (record.type === "libraryPlaylist" || record.type === "playlist")
  );
}
// this will preferentially use a catalog album
// matching name/artistName if there is one. pass in library album
// or musicbrainz album etc.
export function bestRecord(state, record) {
  if (!state.record || !record) return null;
  let ret = null;
  if (record.id) {
    const { idToRecordMap } = state.record;
    const bestRecordId = bestIdForRecord(record);
    ret = idToRecordMap[bestRecordId] || record;
    if (ret.catalogAlbumId) ret = idToRecordMap[ret.catalogAlbumId] || ret;
  } else if (
    record.attributes &&
    record.attributes.name &&
    record.attributes.artistName
  ) {
    const slug = slugForRecord(record);
    ret = combineRecords(state.record.slugToRecordMap[slug], record); // Merge Records Sampler-Various Artists"];
  }
  if (ret) {
    ret.extra = { ...ret.extra, ...record.extra };
    ret.originalId = record.id;
  }
  return ret || record;
}

export function sizedUrl(url, width) {
  if (!url) return null;
  let _url = url.replace("{w}", width);
  _url = _url.replace("{h}", width);
  return _url;
}

export function notchHeight(clientHeight) {
  return isContainedInIosNativeApp() && clientHeight >= 812 ? 31 : 0;
}

export function areRecordsTheSame(a, b) {
  // if (isPlaylist(a)) {
  //   return _.get(b, "_container.id") === a.id;
  // }
  if (!a || !b) return false;
  if (a.id && a.id === b.id) return true;
  if (a && b && a.attributes && b.attributes) {
    const a1 = a.attributes;
    const a2 = b.attributes;
    return (
      a1.artistName === a2.artistName &&
      (a1.albumName || a1.name) === (a2.albumName || a2.name)
    );
  } else return false;
}

export async function processEndpoint(
  endpoint,
  params,
  processor,
  maxResults = null
) {
  const generator = await paginatedFetchWithAuth(endpoint, params, maxResults);
  let done, value;
  do {
    ({ value, done } = await generator.next());
    console.log({endpoint, value, done})
    await processor(value, endpoint);
  } while (!done);
}

export async function* paginatedFetchWithAuth(endpoint, params, maxResults) {
  if (!endpoint) return;
  let numResults = 0;
  do {
    // const url = generateUrl(endpoint,  )
    const responseData = await fetchWithAuth(endpoint, params);
    if (!responseData) return;
    let { data } = responseData;
    if (data) {
      if (maxResults && numResults + data.length >= maxResults) {
        yield _.slice(data, 0, maxResults - numResults);
        endpoint = null;
        break;
      }
      numResults += data.length;
      yield data;
      endpoint = responseData.next;
    } else break;
  } while (endpoint);
}

export async function fetchJson(
  endPoint,
  params,
  useCache = false,
  method = "get",
  addToApiLog = true
) {
  if (!global.cache) global.cache = {};
  const url = generateUrl(endPoint, params, false);
  if (useCache && global.cache[url]) {
    return new Promise(resolve => resolve(global.cache[url]));
  }
  const headers = {
    "Content-Type": "application/json",
    Origin: "https://shelf.fm"
  };
  var xhrObj = {
    method,
    headers
  };
  try {
    const response = await fetch(url, xhrObj);

    const json = await response.json();
    addToApiLog &&
      store.dispatch({
        type: "ADD_TO_API_LOG",
        url,
        json,
        response: response.ok ? "ok" : "fail"
      });
    return json;
  } catch (err) {
    console.log("fetch error", url, err);
  }
}

export async function fetchWithAuth(
  endPoint,
  params,
  useCache = false,
  method = "get",
  wantsResponse = false,
  useParamsAsPostBody = false
) {
  const isPost = method === "post" || method === "put";
  if (!global.cache) global.cache = {};
  const url = generateUrl(endPoint, useParamsAsPostBody ? null : params);
  if (useCache && global.cache[url]) {
    return new Promise(resolve => resolve(global.cache[url]));
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + developerToken,
    "Cache-Control": "max-age=0",

    "User-Agent":
      "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.138 Safari/537.36",
    "Music-User-Token": global.userToken
  };
  var xhrObj = {
    method,
    headers,
    body: isPost ? JSON.stringify(params) : null
  };
  const response = await fetch(url, xhrObj);
  if (response.ok) {
    const responseData = await (wantsResponse || method === "get"
      ? response.json()
      : response);
    if (method === "post") return responseData;
    if (useCache) global.cache[url] = responseData;
    return responseData;
  } else {
    const { type, url } = response;
    const json = await response.json();
    if (json.errors && json.errors.length > 0) {
      const { title, detail, status, code } = json.errors[0];
      const msg = `There was a ${status} error accessing the following url:\r\n${url}\r\n${title}: ${detail}\r\nType: ${type}. Code: ${code} `;
      console.log(msg);
    } else {
      console.log("unknown error fetching data.", endPoint);
    }
    return null;
  }
}

export const generateUrl = (endPoint, params, useBaseUrl = true) => {
  let keyPairs = params
    ? Object.keys(params)
        .map(function(key) {
          let value = removeDiacritics(`${params[key]}`);
          if (value) {
            return key + "=" + encodeURIComponent(value);
          } else return "";
        })
        .join("&")
    : "";
  if (!endPoint) return null;
  const questionMarkOrAmpersand = endPoint.includes("?") ? "&" : "?";
  let queryString = params ? `${questionMarkOrAmpersand}${keyPairs}` : "";
  endPoint = _.replace(endPoint, "/v1/", "");
  const baseUrl = useBaseUrl ? BASE_URL : "";
  return `${baseUrl}${endPoint}${queryString}`;
};
var __DEV__ = true;

export function jlog(obj, displayAsObject = false, includeMethods = false) {
  if (__DEV__) {
    var replacer;
    if (includeMethods) {
      replacer = (k, v) => {
        if (v === undefined) {
          return "undefined";
        }
        if (typeof v === "function") {
          return v.toString();
        }
        return v;
      };
    } else {
      replacer = (k, v) => {
        return v === undefined ? "undefined" : v;
      };
    }
    const ms = new Date().getTime();
    obj.time = (ms / 1000).toFixed(3);
    if (displayAsObject) {
      console.log(obj);
    } else {
      console.log(JSON.stringify(obj, replacer, 2));
    }
  }
}

export function removeRecord(records, record) {
  if (!records) return null;
  if (!record) return records;
  return records.filter(r => !areRecordsTheSame(r, record));
}

export function removeArtist(records, record) {
  if (!records) return null;
  if (!record) return records;
  return records.filter(
    r => !(r.attributes.artistName === record.attributes.artistName)
  );
}

var changes;

export function removeDiacritics(str) {
  if (!str) return str;
  if (!changes) {
    changes = defaultDiacriticsRemovalMap;
  }
  for (var i = 0; i < changes.length; i++) {
    str = str.replace(changes[i].letters, changes[i].base);
  }
  return str;
}

export function serializeFunction(func) {
  const ret = func.toString();
  jlog({ func: ret });
  return ret;
}

export function deserializeFunction(funcString) {
  return new Function(`return ${funcString}`)();
}

export const excludedLabels = [
  "Island Records",
  "Self Released",
  "Columbia",
  "Polydor Records",
  "Columbia/Legacy",
  "Elektra (NEK)",
  "Interscope",
  "Epic",
  "Capitol Records",
  "Warner Records",
  "RCA",
  "Decca",
  "Decca Records",
  "Atlantic",
  "Island",
  "Virgin",
  "Interscope Records"
];

export const rebase1=1
