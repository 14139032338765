import React, { useState, useEffect } from "react";
import connect from "react-redux/es/connect/connect";
import styles from "./shelf-picker.module.scss";
import _ from "lodash";
import { isContainedInIosNativeApp } from "../../helpers/native-ios-mk-implementation";
import { Picker, Item, Section } from "@adobe/react-spectrum";
import { stopPreviewingRecord } from "../../actions/playback-actions";
import { setPickedItem } from "../../actions/ui-actions";
import {
  loadPlaylistRecordsIntoShelf,
  loadPlaylists
} from "../../actions/record-actions";
import { AutoPreviewSwitch } from "../ConnectedPropertySwitch/connected-property-switch";
import {
  RECENTS_SHELF_INDEX,
  SCRATCH_SHELF_INDEX
} from "../../reducers/record-reducer";
import { unauthenticate } from "../../actions/authentication-actions";
import { withCookies } from "react-cookie";

function ShelfPicker(props) {
  const { groups } = props;
  let keyToItemDataMap = {};

  let [sections, setSections] = useState(groupsToSections());

  function onChange(key) {
    const {
      id,
      kind,
      url,
      leftItems,
      layoutCode,
      hideLayoutPicker,
      props: shelfProps
    } = keyToItemDataMap[key];
    switch (kind) {
      case "link":
        window.location.href = url;
        break;
      default:
        props.setPickedItem({
          id,
          key,
          kind,
          leftItems,
          layoutCode,
          hideLayoutPicker,
          props: shelfProps
        });
        break;
    }
  }

  const containerStyle = isContainedInIosNativeApp()
    ? { marginBottom: -18 }
    : {};

  function groupsToSections() {
    return groups.map((group, i) => {
      let groupName = group[0];
      let itemDataElements = _.slice(group, 1, group.length);
      return {
        name: groupName,
        items: itemDataElements.map(itemData => {
          const { id, name, kind, props, key } = itemData;
          keyToItemDataMap = { ...keyToItemDataMap, [key]: itemData };
          return {
            id,
            key,
            name,
            kind,
            itemProps: props
          };
        })
      };
    });
  }

  useEffect(() => {
    setSections(groupsToSections());
  }, [props.groups]);

  function renderItem(item) {
    switch (item.kind) {
      case "shelf":
        return <Item key={item.key}>{item.name}</Item>;
      case "link":
        return <Item key={item.key}>{item.name}</Item>;
      default:
        return <Item key={item.key}>{item.name}</Item>;
    }
  }

  const disabledKeys = props.loggedIn
    ? null
    : [
        "recents",
        "recentlyAdded",
        "newReleases",
        "allRecords",
        "rock",
        "electronic",
        "jazz",
        "rbSoul",
        "rap",
        "reggae",
        "classical",
        "playlists"
      ];
  return (
    <div className={styles.container} style={containerStyle}>
      <Picker
        aria-label={"picker"}
        items={sections}
        isQuiet
        labelAlign="end"
        disabledKeys={disabledKeys}
        onSelectionChange={onChange}
        selectedKey={props.pickedItem.key}
        defaultSelectedKey={props.pickedItem.key}
      >
        {section => (
          <Section
            key={section.name}
            title={section.name}
            items={section.items}
          >
            {renderItem}
          </Section>
        )}
      </Picker>
    </div>
  );
}

export const recentlyPlayedShelfItem = {
  key: "recentlyPlayed",
  id: SCRATCH_SHELF_INDEX,
  kind: "shelf",
  name: "Recently Played",
  // leftItems: ["AutoPreviewSwitch"],
  layoutCode: "m",

  props: {
    key: "recentlyPlayed",
    orbitQueryFunctionMapKey: "loadRecentlyPlayed",
    recordClass: "library-bold",
    sortOptionsIndex: 0,
    sortOptions: [
      {
        sortRecordsBy: "index",
        reverse: true
      }
    ],
    recordProps: {
      playOnHover: true,
      ignoreVisibility: false
    }
  }
};

export const recentlyAddedShelfItem = {
  key: "recentlyAdded",
  id: SCRATCH_SHELF_INDEX,
  kind: "shelf",
  name: "Recently Added",
  // leftItems: ["AutoPreviewSwitch"],
  layoutCode: "m",

  props: {
    key: "recentlyAdded",
    orbitQueryFunctionMapKey: "loadRecentlyAdded",
    recordClass: "library-bold",
    recordProps: {
      playOnHover: true,
      ignoreVisibility: false
    }
  }
};

export const staffPicksShelfItem = {
  key: "shelfPicks",
  id: SCRATCH_SHELF_INDEX,
  kind: "shelf",
  name: "Staff Picks",
  leftItems: ["AutoPreviewSwitch"],
  layoutCode: "m",

  props: {
    key: "shelfPicks",
    loadRecordsFunctionMapKey: "loadStaffPicks",
    recordClass: "catalogNew",
    sortOptionsIndex: 0,
    sortOptions: [
      {
        sortRecordsBy: "index",
        reverse: true
      }
    ],
    recordProps: {
      playOnHover: true,
      ignoreVisibility: false
    }
  }
};

export const recentsShelfItem = {
  key: "recents",
  kind: "shelf",
  id: RECENTS_SHELF_INDEX,
  name: "Recently Played/Added",
  layoutCode: "s",
  props: {
    sortOptionsIndex: 0,
    sortOptions: [
      {
        sortRecordsBy: r => parseInt(r.index)
      }
    ]
  }
};

const mapStateToProps = state => {
  let groups = [
    [
      null,
      recentlyPlayedShelfItem,
      recentlyAddedShelfItem,
    // ],
    // [
    //   "",
    //   {
    //     key: "spinitron",
    //     id: state.record.scratchShelfIndex,
    //     kind: "shelf",
    //     name: "Spinitron Top 50 College Albums This Week",
    //     props: {
    //       key: "spinitron",
    //
    //       loadRecords: id => dispatch => dispatch(loadSpinitronTop50()),
    //       // sortOptions: [
    //       //   {
    //       //     sortRecordsBy: "attributes.releaseDate",
    //       //     reverse: true
    //       //   }
    //       // ],
    //       recordClass: "catalogNew",
    //       recordProps: {
    //         playOnHover: true,
    //         ignoreVisibility: false
    //       }
    //     }
    //   },
      {
        key: "playlists",
        kind: "shelf",
        id: state.record.orbitShelfIndex,
        name: "Playlists",
        layoutCode: "m",
        hideLayoutPicker: true,
        props: {
          loadRecordsFunctionMapKey: "loadPlaylists",
          orbitQueryFunctionMapKey: "libraryPlaylistsQuery",
          sortOptions: [
            {
              sortRecordsBy: "attributes.name"
            }
          ],
          recordClass: "playlists",
          klass: "playlists",
          recordProps: {
            playOnHover: true
          }
        },

      },
      // {
      //   key: "allRecords",
      //   name: "Library",
      //   kind: "shelf",
      //   id: state.record.libraryRecordsShelfIndex,
      //   props: {
      //     sortOptionsIndex: 0,
      //   },
      //   layoutCode: "s"
      // },
      {
        key: "newReleases",
        kind: "shelf",
        active: false,
        id: state.record.labelRecordsShelfIndex,
        name: "New Releases",
        //leftItems: ["AutoPreviewSwitch"],
        props: {
          sortOptions: [
            {
              sortRecordsBy: "attributes.releaseDate",
              reverse: true
            }
          ],
          recordClass: "catalogNew",
          recordProps: {
            playOnHover: true,
            ignoreVisibility: true
          }
        }
      },
      {
        key: "pitchfork",
        id: state.record.scratchShelfIndex,
        kind: "shelf",
        name: "Pitchfork Selects",
        leftItems: ["AutoPreviewSwitch"],
        layoutCode: "m",

        props: {
          key: "pitchfork",
          loadRecordsFunctionMapKey: "loadPitchforkSelects",
          recordClass: "catalogNew",
          recordProps: {
            playOnHover: true,
            ignoreVisibility: false
          }
        }
      }
    // ],
    // [
    //   "feedback/questions",
    //   {
    //     key: "feedback",
    //     kind: "link",
    //     id: "feedback",
    //     name: "feedback",
    //     url: "mailto:marstall@gmail.com?subject=shelf.fm"
    //   },
    ]
  ];
  // {
  //   key: "twitter",
  //   kind: "link",
  //   id: "twitter",
  //   name: "@shelf_fm",
  //   url: "https://www.twitter.com/shelf_fm"
  // }
  // {
  //   key: "logout",
  //   kind: "dispatch",
  //   id: "logout",
  //   name: "Log Out",
  //   action: (dispatch,cookies)=>{
  //     dispatch(unauthenticate())
  //   }
  //}
  // {
  //   key: "rock",
  //   kind: "shelf",
  //   id: state.record.libraryRecordsShelfIndex,
  //   name: "Rock",
  //   layoutCode: "s",
  //   props: {
  //     filterPredicates: [{ genre: "Rock" }, { minTracks: 2 }]
  //   }
  // },
  // {
  //   key: "electronic",
  //   kind: "shelf",
  //   layoutCode: "s",
  //   id: state.record.libraryRecordsShelfIndex,
  //   name: "Electronic",
  //   props: {
  //     filterPredicates: [{ minTracks: 1 }]
  //   }
  // },
  // {
  //   key: "rbSoul",
  //   kind: "shelf",
  //   layoutCode: "s",
  //   id: state.record.libraryRecordsShelfIndex,
  //   name: "R&B/Soul",
  //   props: {
  //     filterPredicates: [{ minTracks: 2 }]
  //   }
  // },
  // {
  //   key: "rap",
  //   kind: "shelf",
  //   layoutCode: "s",
  //   id: state.record.libraryRecordsShelfIndex,
  //   name: "Rap & Hip-Hop",
  //   props: {
  //     filterPredicates: [{ genre: "Rap & Hip-Hop" }, { minTracks: 2 }]
  //   }
  // },
  // {
  //   key: "reggae",
  //   kind: "shelf",
  //   id: state.record.libraryRecordsShelfIndex,
  //   name: "Reggae",
  //   layoutCode: "s",
  //   props: {
  //     filterPredicates: [{ genre: "Reggae" }, { minTracks: 2 }]
  //   }
  // },
  // {
  //   key: "jazz",
  //   kind: "shelf",
  //   id: state.record.libraryRecordsShelfIndex,
  //   name: "Jazz",
  //   layoutCode: "s",
  //   props: {
  //     filterPredicates: [{ genre: "Jazz" }, { minTracks: 2 }]
  //   }
  // },
  // {
  //   key: "classical",
  //   kind: "shelf",
  //   id: state.record.libraryRecordsShelfIndex,
  //   name: "Classical",
  //   layoutCode: "s",
  //   props: {
  //     filterPredicates: [{ genre: "Classical" }, { minTracks: 1 }]
  //   }
  // }
// staffPicksShelfItem,


  // ],
  // [
  //   "your library",
  // {
  //   key: "allRecords",
  //   kind: "shelf",
  //   id: state.record.libraryRecordsShelfIndex,
  //   leftItems: ["SortingSwitch"],
  //   layoutCode: "s",
  //
  //   name: "Artists",
  //   props: {
  //     filterPredicates: [{ minTracks: 2 }],
  //     sortOptions: [
  //       {
  //         sortRecordsBy: "attributes.artistName",
  //         inlineLabels: true,
  //         name: "Artist"
  //       }
  //     ]
  //   }
  // },
  const searchResultArtists = _.get(state.search, "results.artists.data");
  const searchResultAlbums = _.get(state.search, "results.albums.data");
  const selectedArtist = _.get(state.search, "selectedArtist");
  if (searchResultArtists || searchResultAlbums || selectedArtist) {
    groups = [
      [
        "Search",
        {
          key: "searchResults",
          kind: "search",
          name: `"${state.search.text}"`
        }
      ],
      ...groups
    ];
  }

  return {
    loggedIn: state.authentication.loggedIn,
    groups,
    shelves: state.record.shelves,
    pickedItem: state.ui.pickedItem || recentlyPlayedShelfItem,
    previewingRecord: state
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setPickedItem: itemData => {
      setTimeout(() => dispatch(stopPreviewingRecord()), 2000);
      dispatch(setPickedItem(itemData));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(ShelfPicker));
