import React, { useState, useEffect, useRef } from "react";
import { unstable_trace as trace } from "scheduler/tracing";

import connect from "react-redux/es/connect/connect";
import { Pressable, TouchableOpacity } from "react-native-web";
import {
  playPause,
  recordPlayPressed,
  recordSelected,
  startPreviewingRecord,
  stopPreviewingRecord,
  transitionPreviewToPlay
} from "../../actions/playback-actions";
import {
  addRecord,
  addRecordsToMap,
  deleteRecord,
  loadRecordIfNeeded,
  rateRecord,
  setSelectedRecord
} from "../../actions/record-actions";
import { BrowserView } from "react-device-detect";
import {
  areRecordsTheSame,
  bestRecord,
  debounce,
  jlog,
  mkplayer,
  sizedUrl,
  slugForRecord,
  uiVariables,
  isPlaylist,
  setCssVariable,
  processEndpoint,
  fetchWithAuth,
  randomImageUrl,
  randomizeAttributes
} from "../../helpers";
import styles from "./record.module.scss";
//import styles from "./fast-record.module.scss";
import _ from "lodash";
import moment from "moment";
import { artistSelectedWithRecord } from "../../actions/search-actions";
import {
  SET_VISIBILITY,
  SOURCE_RECENTLY_PLAYED_AM,
  SOURCE_RECENTLY_PLAYED_HERE,
  TRACKS_LOADED_FOR_RECORD,
  TURN_RECORD_OVER
} from "../../reducers/record-reducer";
import CoverHud from "../CoverHud/cover-hud";
import {
  CATALOG_ID_APPLE_MUSIC_NEW_RELEASES,
  CATALOG_ID_LABEL_NEW_RELEASES,
  sourceColorMap
} from "../../reducers/catalogs-reducer";
import { flag, Flags } from "../../helpers/flags";
import VisibilitySensor from "../../helpers/visibility-sensor";
import { isContainedInIosNativeApp } from "../../helpers/native-ios-mk-implementation";
import {
  setBookMark,
  setPaletteBasedOnRecord,
  setPickedItem
} from "../../actions/ui-actions";
import { SET_SCROLLING } from "../../reducers/ui-reducer";
import RecordPlaceholder from "./record-placeholder";
import { reportEvent } from "../../analytics";

function getRandomId() {
  return Math.floor(Math.random() * Math.floor(1000000));
}

const PRESS_ANIMATION_DURATION = 150;

function Record(props) {
  const {
    stopPreviewingRecord,
    style = {},
    rating,
    klass = "default",
    showDeleted,
    labelArtists,
    previewReady,
    musicbrainzOnly,
    showHoverText = false,
    showCoverHud = true,
    includeSingles = false,
    forceLoad = false,
    ignorePreviewReady = false,
    layoutCode
  } = props;

  let { previewStatus, playOnHover } = props;
  if (isContainedInIosNativeApp()) playOnHover = false;
  if (previewStatus === "playing") previewStatus = "previewing";
  //if (previewStatus === "stopped") previewStatus = "";
  const [record, setRecord] = useState(props.record);
  const [isAPlaylist, setIsAPlaylist] = useState(isPlaylist(props.record));
  const [noCoverArt, setNoCoverArt] = useState(isPlaylist(false));
  const [hover, setHover] = useState(false);
  const [coverHover, setCoverHover] = useState(false);
  const [isInAppleMusic, setIsInAppleMusic] = useState(
    !!_.get(props.record, "attributes.artwork" || props.coverArtImage)
  );
  const [previewRequested, setPreviewRequested] = useState(false);
  const [isThisRecordPreviewing, setIsThisRecordPreviewing] = useState(false);
  const [isAnotherRecordPreviewing, setIsAnotherRecordPreviewing] = useState(
    false
  );
  const [layoutRect, setLayoutRect] = useState(null);
  const [showJson, setShowJson] = useState(false);
  const [imgRect, setImgRect] = useState(null);
  const [domId, setDomId] = useState(getRandomId());
  const [imageLoadFailed, setImageLoadFailed] = useState(false);
  const [coverUiState, setCoverUiState] = useState("unpressed");
  const [imageLoaded, setImageLoaded] = useState(false);
  const numRenders = useRef(0);
  const [
    recordWasReloadedIfItWasNeeded,
    setRecordWasReloadedIfItWasNeeded
  ] = useState(false);
  const containerRef = React.createRef();
  const coverHoverRef = useRef(coverHover);

  numRenders.current = numRenders.current + 1;

  useEffect(() => {
    if (!props.selectedRecordId) {
      getLayoutInfo();
    }
  }, [props.klass]);

  useEffect(() => {
    if (props.record.id) {
      setRecord(props.record);
      setIsAPlaylist(isPlaylist(props.record));
      setIsInAppleMusic(
        !!(
          (record.attributes.artwork && record.attributes.artwork.url) ||
          props.coverArtImage
        )
        //record.attributes.artwork.width
      );
      getLayoutInfo();
    }
    numRenders.current = 0;
  }, [props.record.id]);

  useEffect(() => {
    if (!isThisRecordPreviewing) {
      setPreviewRequested(false);
    }
  }, [isThisRecordPreviewing]);

  useEffect(() => {
    setIsAnotherRecordPreviewing(
      record.id !== _.get(props, "currentlyPreviewingRecord.id") &&
        _.get(props, "currentlyPreviewingRecord.id")
    );
    setIsThisRecordPreviewing(
      record.id === _.get(props, "currentlyPreviewingRecord.id") ||
        (record.id === _.get(props, "mediaItem._container.id") &&
          !props.currentlyPlayingRecord)
    );
  }, [
    record.id,
    _.get(props, "currentlyPreviewingRecord.id"),
    _.get(props, "mediaItem._container.id"),
    props.playbackStateName
  ]);

  useEffect(() => {
    if (!props.selectedRecordId) {
      getLayoutInfo();
    }
  }, [props.selectedRecordId]);

  useEffect(() => {
    props.setCurrentGlobalRecordLayoutRect(layoutRect);
    // props.visible &&
    //   layoutRect &&
    //   debounce("layout", () =>
    //     props.setCurrentGlobalRecordLayoutRect(layoutRect)
    //   );
  }, [JSON.stringify(layoutRect)]);

  useEffect(() => {
    setTimeout(() => setCoverUiState("unpressed"), 1350);
  }, [props.isSelected]);

  useEffect(() => {
    if (props.visible) {
      getLayoutInfo();
      window.addEventListener("resize", handleResize);
    } else {
      window.removeEventListener("resize", handleResize);
      props.stopPreviewingRecord(record);
    }
  }, [props.visible]);

  useEffect(() => {
    coverHoverRef.current = coverHover;
  }, [coverHover]);

  useEffect(() => {
    if (coverUiState === "pressed" && props.isScrolling) {
      setCoverUiState("unpressed");
    }
  }, [props.isScrolling]);

  useEffect(() => {
    if (!props.record.id || forceLoad) {
      props.loadRecordIfNeeded(props.record, forceLoad, _record => {
        // _record = flag(Flags.useRandomRecordData)
        //   ? randomizeAttributes(record)
        //   : _record;
        //setRecord(_record);
        // getLayoutInfo();
        setRecordWasReloadedIfItWasNeeded(true);
        _record &&
          setIsInAppleMusic(
            !!(
              (_record.attributes.artwork &&
                _record.attributes.artwork.url &&
                _record.attributes.artwork.width) ||
              props.coverArtImage
            )
          );
        props.onLoad && props.onLoad(_record);
      });
    } else {
      setRecordWasReloadedIfItWasNeeded(true);
      props.onLoad && props.onLoad(props.record);
    }
  }, [JSON.stringify(props.record)]);

  useEffect(() => {
    if (isAPlaylist && !props.coverArtImage) {
      fetchWithAuth("me/library/playlists/" + record.id + "/tracks")
        .then(tracks => {
          const firstTrackArtworkUrl = _.get(
            tracks,
            "data[0].attributes.artwork.url"
          );
          if (firstTrackArtworkUrl) {
            props.setCoverArt(record, firstTrackArtworkUrl);
          } else {
            setNoCoverArt(true);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }

    // processEndpoint(
    //   `me/library/playlists/${record.id}/tracks`,
    //   {},
    //   _tracks => {
    //     if (_tracks) tracks = [...tracks, ..._tracks];
    //   },
    //   1
    // );
  }, []);

  useEffect(() => {
    loadImage();
  }, [layoutCode, props.coverArtImage]);

  function getLayoutInfo() {
    if (props.selectedRecordId) return;
    const element = document.getElementById("" + domId);
    if (element) {
      const rect = element.getBoundingClientRect();
      setLayoutRect(rect);

      const img = element.querySelector("img");
      if (img && imageLoaded) {
        const imgRect = img.getBoundingClientRect();
        setImgRect(imgRect);
      }
      return rect;
    }
  }

  function handleResize() {
    getLayoutInfo();
  }

  async function onCoverHoverEnter() {
    setCoverHover(true);
    if (props.autoPreviewingSwitchOn) {
      //if (coverHoverRef.current) {
      if (!playOnHover) return;
      if (previewReady === false) return;
      if (!flag(Flags.previewingActive)) return;
      if (props.somethingIsActivelyPlaying) return;
      setPreviewRequested(true);
      props.startPreviewingRecord(record, layoutRect);
      // }
    }
  }

  function onCoverHoverExit() {
    setCoverHover(false);
    if (!playOnHover) return;
    if (!flag(Flags.previewingActive)) return;
    if (
      props.currentlyPlayingRecord ||
      props.currentlyPlayingTrack
      // && !props.isPlaybackInPausedState
    )
      return;

    stopPreviewingRecord(record);
  }

  function onPressIn() {
    props.setScrolling(false);
    if (coverUiState !== "selected") {
      setCoverUiState("pressed");
    }
  }

  function onPressOut() {
    setCoverUiState("unpressed");
    // const element = document.getElementById("" + domId);
    // props.recordSelected(record, element.getBoundingClientRect());

    // setTimeout(() => {
    //   const element = document.getElementById("" + domId);
    //   props.recordSelected(record, element.getBoundingClientRect());
    // }, PRESS_ANIMATION_DURATION / 2);
    //
    // if ((coverUiState==="selected"||props.isPlaying)||props.scrollY!==pressY) {
    //   setCoverUiState("unpressed");
    // } else {
    //   if (coverUiState!=="pressed") return;
    //
    // }
  }

  async function longPressTapped(event, record) {
    props.longPressed(event, record, layoutRect, props, isThisRecordPreviewing); // same as a regular play but don't redirect to now playing item
  }

  async function recordTapped(event, record) {
    //setCoverUiState("selected");
    const element = document.getElementById("" + domId);
    props.recordSelected(record, element.getBoundingClientRect());
    //setTimeout(() => props.recordSelected(record), 500);
    // props.setBookmark();
    //
    // if (onTap) {
    //   const ret = onTap(record);
    //   if (!ret) return;
    // }
    setCoverHover(false);
    //
    reportEvent(
      "record tapped",
      `${record.attributes.name} - ${record.attributes.artistName}`
    );
    // if (props.recordTapped) {
    //   props.recordTapped(record, layoutRect);
    // }
    //});
  }

  if (!flag(Flags.debugRecord) && !showDeleted && rating === -1) return null;
  if (!flag(Flags.debugRecord) && imageLoadFailed) return <div>fail</div>;
  const a = record.attributes || {};

  const source = _.get(record, "extra.source");
  const batch = _.get(record, "extra.batch");
  const cursor = _.get(record, "extra.cursor");
  const withinBatchIndex = _.get(record, "extra.withinBatchIndex");
  let sourceStyle = {};
  let idStyle = { display: "none" };
  if (flag(Flags.debugRecord)) {
    // let sourceColor = "red";
    // if (source === SOURCE_RECENTLY_PLAYED_AM) sourceColor = "yellow";
    // if (source === SOURCE_RECENTLY_PLAYED_HERE) sourceColor = "white";
    // sourceStyle = { backgroundColor: sourceColor };
    idStyle = { display: "block" };
  }
  const title =
    showHoverText &&
    `${a.artistName}\r\n${a.name}\r\n${a.recordLabel}\r\n${moment(
      a.releaseDate,
      "YYYY-MM-DD"
    ).format("YYYY")}`;

  function SourceIndicator(props) {
    const source = _.get(props.record, "extra.catalogSource");
    if (!source) return null;
    const backgroundColor = sourceColorMap[source];
    return (
      <div className={styles.sourceIndicator} style={{ backgroundColor }} />
    );
  }

  let coverOpacity = null;
  let coverZIndex = null;
  let coverScale = 1;

  switch (coverUiState) {
    case "pressed":
      setCssVariable(
        "recordAnimationDuration",
        `${PRESS_ANIMATION_DURATION}ms`
      );
      coverScale = 0.9;
      coverOpacity = "0.8";
      coverZIndex = 0;
      break;
    case "unpressed":
      setCssVariable(
        "recordAnimationDuration",
        `${PRESS_ANIMATION_DURATION}ms`
      );
      coverScale = previewRequested ? 1.02 : 1;
      coverOpacity = "1";
      coverZIndex = 0;
      break;
    default:
      break;
  }

  const coverTransform = `scale(${coverScale})`;
  //  const coverOpacity = coverPressedIn ? "0.8" : "1";

  let coverStyle = {
    ...props.coverStyle,
    transform: coverTransform,
    zIndex: coverZIndex,
    opacity: imageLoaded ? 1 : 1
  };
  coverStyle = props.isPlaying
    ? { ...coverStyle, borderColor: "white" }
    : coverStyle;
  const linesStyle = props.isPlaying ? { color: "white" } : {};
  const playedAtStyle={opacity:1,fontSize: "1em",fontStyle:"italic",...linesStyle};
  const catalogSource = _.get(props.record, "extra.catalogSource");
  let albumCatalogSourceIndicator = catalogSource ===
    CATALOG_ID_APPLE_MUSIC_NEW_RELEASES && <SourceIndicator record={record} />;
  let labelCatalogSourceIndicator = catalogSource ===
    CATALOG_ID_LABEL_NEW_RELEASES && <SourceIndicator record={record} />;

  if (a.isSingle && !includeSingles) return null;

  const imgStyle = imgRect && {
    ...props.imgStyle,
    objectFit: "contain",
    height: imgRect.width,
    border: 0,
    padding: 0,
    margin: 0
  };
  let showPreviewStatus = isThisRecordPreviewing;
  //&&
  // playOnHover &&
  // coverHover &&
  // !props.currentlyPlayingRecord &&
  // !props.currentlyPlayingTrack;

  const opacity = isAnotherRecordPreviewing ? 0.1 : 1;

  const imgDivWidth = layoutRect ? layoutRect.width : null;
  const imgDivStyle = {
    width: imgDivWidth,
    height: imgDivWidth
  };

  if (!isInAppleMusic && recordWasReloadedIfItWasNeeded && !isAPlaylist)
    return null;
  //if (!record.id) return null;
  // if (record.attributes.artistName === "The Dickies") {
  //   jlog({ renderRecord: record });
  // }
  if (!record.attributes) return null;

  //if (noCoverArt) return null;

  function loadImage() {
    const rawImageUrl =
      props.coverArtImage || _.get(record.attributes, "artwork.url");
    const imageUrl = flag(Flags.useRandomRecordData)
      ? randomImageUrl(record)
      : sizedUrl(rawImageUrl, layoutCode === "s" ? 200 : 300);
    const img = document.getElementById(`img-${domId}`);
    if (img && imageUrl) {
      img.src = imageUrl;
      img.onerror = () => {
        setImageLoadFailed(true);
      };
    }
  }

  function onLoadImage() {
    props.onImageLoad && props.onImageLoad();
    setImageLoaded(true);
  }

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{ position: "absolute", left: 0, top: 0, bottom: 0, right: 0 }}
      >
        <RecordPlaceholder
          record={record}
          width={_.get(props.currentGlobalRecordLayoutRect, "width")}
          height={_.get(props.currentGlobalRecordLayoutRect, "width")}
        />
      </div>
      <VisibilitySensor
        tag={record.id}
        active={true}
        partialVisibility={true}
        onChange={visible => {
          if (visible) {
            loadImage();
          }
        }} // dont go non visible
        offset={{ bottom: layoutCode === "s" ? 0 : 0 }}
      >
        <div
          id={domId}
          ref={containerRef}
          className={isAPlaylist ? styles.playlist : styles[klass]}
          style={{ ...style, ...sourceStyle, opacity }}
        >
          <div
            id={record.id}
            className={styles[`cover`]}
            style={coverStyle}
            onMouseEnter={onCoverHoverEnter}
            onMouseLeave={onCoverHoverExit}
          >
            <TouchableOpacity
              delayPressIn={0}
              activeOpacity={0.9}
              onPressIn={() => onPressIn()}
              onPressOut={() => onPressOut()}
              onPress={e => recordTapped(e, record)} //setTimeout(() => recordTapped(e, record), 100)}
              onLongPress={e => longPressTapped(e, record)}
            >
              {(isInAppleMusic || isAPlaylist) && !noCoverArt ? (
                <img
                  id={`img-${domId}`}
                  data-recordid={record.id}
                  style={imgStyle}
                  title={title ? title : undefined}
                  onLoad={onLoadImage}
                />
              ) : (
                <div style={imgDivStyle}></div>
              )}
            </TouchableOpacity>
            {/*{showPreviewStatus && (*/}
            {/*  <div className={styles.previewing}>*/}
            {/*    {props.playbackStateName === "playing"*/}
            {/*      ? "previewing"*/}
            {/*      : props.playbackStateName}*/}
            {/*  </div>*/}
            {/*)}*/}
            {/*<div className={styles.coverDebugOverlay}>*/}
            {/*  {props.visible && "visible"}*/}
            {/*</div>*/}

            {showCoverHud && (
              <CoverHud
                active={coverHover}
                layoutRect={layoutRect}
                record={record}
              />
            )}
          </div>
          <div className={styles["spine"]}>
            <div className={styles["lines"]} style={linesStyle}>
              <div className={styles["record-id"]} style={idStyle}>
                {record.id}
              </div>
              <div className={styles["extra"]} style={idStyle}>
                index: {a.index}
              </div>

              <TouchableOpacity
                onPressIn={() => onPressIn()}
                onPressOut={() => onPressOut()}
                onPress={e => recordTapped(e, record)} //setTimeout(() => recordTapped(e, record), 100)}
                onLongPress={e => longPressTapped(e, record)}
              >
                {/*<div className={styles["spine-label"]} style={linesStyle}>*/}
                {/*  <div*/}
                {/*    className={styles["spine-label-artist-name"]}*/}
                {/*    style={linesStyle}*/}
                {/*  >*/}
                {/*    {a.artistName}*/}
                {/*  </div>*/}
                {/*  {a.name !== a.artistName && (*/}
                {/*    <div*/}
                {/*      className={styles["spine-label-name"]}*/}
                {/*      style={linesStyle}*/}
                {/*    >*/}
                {/*      {a.name}*/}
                {/*    </div>*/}
                {/*  )}*/}
                {/*  <div*/}
                {/*    className={styles["spine-label-record-label"]}*/}
                {/*    style={linesStyle}*/}
                {/*  >*/}
                {/*    {a.recordLabel}*/}
                {/*  </div>*/}
                {/*</div>*/}
              </TouchableOpacity>
              <TouchableOpacity onPress={() => setShowJson(b => !b)}>
                <div className={styles["artist-name"]} style={linesStyle}>
                  {albumCatalogSourceIndicator} {a.artistName}


                  {!labelArtists && (
                    <span className={styles["inlib"]}>(in your library)</span>
                  )}
                </div>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => setShowJson(b => !b)}>
                <div className={styles["name"]} style={linesStyle}>
                  {a.name || "n/a"}{" "}
                  {a.area &&
                    a.area !== "United States" &&
                    a.area !== "[Worldwide]" &&
                    `(${a.area})`}
                  {musicbrainzOnly && " *"}
                </div>
              </TouchableOpacity>
              <div className={styles["label"]} style={linesStyle}>
                {labelCatalogSourceIndicator} {a.recordLabel}
                <a
                  target={"#"}
                  href={`http://www.coverartarchive.org/release/${a.musicbrainzId}`}
                />
                {labelArtists && (
                  <span className={styles["labelArtist"]}>
                    ({labelArtists})
                  </span>
                )}
              </div>
              <div>
                {/*{a.previews && (*/}
                {/*    <audio controls>*/}
                {/*      <source src={a.previews[0].url}/>*/}
                {/*    </audio>)}*/}
              </div>
              <div className={styles["release-date"]} style={linesStyle}>
                {moment(a.releaseDate).add(5, "year") > moment()
                  ? moment(a.releaseDate, "YYYY-MM-DD").fromNow()
                  : moment(a.releaseDate, "YYYY-MM-DD").format("YYYY")}
              </div>
              <div className={styles["release-date-absolute"]}>
                {moment(a.releaseDate, "YYYY-MM-DD").format("YYYY")}
              </div>
              <span className={styles["name"]} style={playedAtStyle}>
                                    {a.playedAt && moment(a.playedAt).fromNow()}

              </span>
              <div
                className={styles.json}
                style={{display: showJson ? "block" : "none"}}
              >
                <pre>{JSON.stringify({...record}, null, 2)}</pre>
              </div>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  let { record } = ownProps;
  if (!record) return null;

  // if (!record.id) {
  //   const slug = slugForRecord(record);
  //   record = state.record.slugToRecordMap[slug];
  //   if (!record) return {};
  // }

  const slugRecord = state.record.slugToRecordMap[slugForRecord(record)];
  record = slugRecord ? slugRecord : record;
  if (!record.id) return;

  //record = randomizeAttributes(record);
  const currentGlobalRecordLayoutRect = state.ui.currentGlobalRecordLayoutRect;

  //record = bestRecord(state, record) || record;
  if (!record.attributes)
    return { record: { href: `/v1/catalog/us/albums/${record.id}` } };
  const { recentsShelfIndex, labelToArtistMap } = state.record;
  const _labelArtists = labelToArtistMap[record.attributes.recordLabel];
  const labelArtistsFiltered = (_labelArtists || []).filter(artist => {
    return _.toLower(artist) !== _.toLower(record.attributes.artistName);
  });

  const labelArtists =
    labelArtistsFiltered && labelArtistsFiltered.length > 0
      ? _.join(_.slice(_labelArtists, 0, 1), ", ")
      : null;

  // is added?
  const shelf = state.record.shelves[recentsShelfIndex];
  const addedRecords = shelf.records;
  const isAdded = addedRecords.reduce((acc, addedRecord) => {
    return areRecordsTheSame(record, addedRecord) | acc;
  }, false);

  // rating
  const idRating = state.record.ratingsMap[record.id];
  const catalogAlbumIdRating = state.record.ratingsMap[record.catalogAlbumId];
  const originalIdRating = state.record.ratingsMap[record.originalId];
  const rating = idRating || catalogAlbumIdRating || originalIdRating;

  // const isTurnedOver =
  //   _.get(state, "playback.currentlyPlayingRecord.id") &&
  //   _.get(state, "playback.currentlyPlayingRecord.id") ===
  //     bestRecord(state, record).id;
  // console.log(
  //   "playback.currentlyPlayingRecord.id",
  //   _.get(state, "playback.currentlyPlayingRecord.id")
  // );
  //console.log("id", bestRecord(state, record).id);
  // _.get(state.record.turnedOverRecord, "recordId") &&
  // _.get(state.record.turnedOverRecord, "recordId") ===
  //   bestRecord(state, record).id;
  const isSelected =
    _.get(state, "lightweightRecord.selected.record.id") === record.id;

  const musicbrainzId = _.get(record, "attributes.musicbrainzId");
  const currentlyPlayingRecord = _.get(
    state,
    "playback.currentlyPlayingRecord"
  );
  const currentlyPlayingTrack = _.get(state, "playback.currentlyPlayingTrack");
  const currentlyPreviewingRecord = _.get(
    state,
    "playback.currentlyPreviewingRecord"
  );

  const isPlaying = areRecordsTheSame(currentlyPlayingRecord, record);
  const coverArtImage = state.record.recordIdToCoverArtImageMap[record.id];
  const tracks = state.record.recordIdToTracksMap[record.id];
  const PlaybackStates = _.get(window, "MusicKit.PlaybackStates");

  return {
    somethingIsActivelyPlaying:
      _.get(state, "playback.playbackState") ===
      _.get(PlaybackStates, "playing"),
    tracks,
    autoPreviewingSwitchOn: state.ui.switches["autoPreview"] === 1,
    clientHeight: state.ui.documentElement.clientHeight,
    clientWidth: state.ui.documentElement.clientWidth,
    scrollY: state.ui.scroll[1],
    isScrolling: state.ui.isScrolling,
    //visible,
    isSelected,
    selectedRecordId: _.get(state.lightweightRecord, "selected.record.id"),
    //slugToRecordMap: state.record.slugToRecordMap,
    // isTurnedOver,
    coverArtImage,
    //turnedOverRecordId: _.get(state.record.turnedOverRecord, "recordId"),
    //turnedOverDomId: _.get(state.record.turnedOverRecord, "domId"),
    previewReady: state.ui.previewReady,
    labelArtists,
    rating,
    originalIdRating,
    idRating,
    catalogAlbumIdRating,
    musicbrainzOnly:
      musicbrainzId && state.record.mbidToMusicbrainzOnlyMap[musicbrainzId],
    palette: state.ui.palette,
    isAdded,
    loaded: !!record.attributes,
    mediaItem: state.playback.mediaItem,
    record,
    recentsShelfIndex,
    currentlyPlayingRecord,
    currentlyPlayingTrack,
    isPlaybackInPausedState: state.playback.playbackStateName !== "playing",
    playbackStateName: state.playback.playbackStateName,
    isPlaying,
    currentlyPreviewingRecord,
    currentGlobalRecordLayoutRect
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCoverArt: (record, url) => {
      dispatch({ type: "SELECT_COVER_ART", record, image: url });
    },
    tracksLoadedForRecord: (record, tracks) =>
      dispatch({ type: TRACKS_LOADED_FOR_RECORD, record, tracks }),

    setCurrentGlobalRecordLayoutRect: rect => {
      dispatch({ type: "setCurrentGlobalRecordLayoutRect", rect });
    },
    setScrolling: b => {
      dispatch({ type: SET_SCROLLING, b });
    },
    setVisibility: (id, visible) => {
      //jlog({visible:{id,visible}})
      dispatch({ type: SET_VISIBILITY, id, visible });
    },
    startPreviewingRecord: (record, layoutRect) =>
      dispatch(startPreviewingRecord(record, layoutRect)),
    stopPreviewingRecord: record => dispatch(stopPreviewingRecord(record)),
    artistClicked: async function(record) {
      dispatch(artistSelectedWithRecord(record), false);
    },
    setBookmark: () => {
      dispatch(setBookMark());
    },
    turnRecordOver: (record, domId) => {
      dispatch({ type: TURN_RECORD_OVER, record, domId });
    },

    loadRecordIfNeeded: (record, forceLoad, onLoad) => {
      dispatch(loadRecordIfNeeded(record, forceLoad, onLoad));
    },
    forceLoadRecord: record => {
      dispatch(loadRecordIfNeeded(record, true));
    },
    addRecordToMap: record => {
      dispatch(addRecordsToMap([record]));
    },
    deleteRecord: (shelfIndex, id) => {
      dispatch(deleteRecord(shelfIndex, id));
    },
    addRecord: (shelfIndex, id) => {
      dispatch(addRecord(shelfIndex, id));
    },
    recordSelected: (record, layoutRect) => {
      dispatch(recordSelected(record, layoutRect, isPlaylist(record)));
    },
    longPressed: (event, record, layoutRect, props, isPreviewing) => {
      event.stopPropagation();
      dispatch(setPaletteBasedOnRecord(record));
      if (isPreviewing) {
        props.transitionPreviewToPlay(layoutRect);
      } else if (props.isPlaying) {
        props.playPause();
      } else dispatch(recordPlayPressed(record, false));
    },
    pickNowPlaying: () => {
      dispatch(setPickedItem({ key: "nowPlaying", kind: "record" }));
    },
    playPause: () => dispatch(playPause()),
    transitionPreviewToPlay: layoutRect => {
      console.log(layoutRect);
      dispatch(transitionPreviewToPlay(layoutRect));
    },
    recordPlayPressed: (event, record, layoutRect) => {
      event.stopPropagation();
      dispatch(setSelectedRecord(record, layoutRect));
      setTimeout(() => {
        dispatch(recordPlayPressed(record));
      }, 500);
    }

    //recordTapped: (record) => dispatch(recordPlayPressed(record))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Record);

/*
      "record": {
      "id": "l.gwpyojs",
      "type": "library-albums",
      "href": "/v1/me/library/albums/l.gwpyojs",
      "attributes": {
      "name": "Europa - EP",
      "playParams": {
      "id": "l.gwpyojs",
      "kind": "album",
      "isLibrary": true
    },
      "artistName": "Diplo",
      "artwork": {
      "width": 1200,
      "height": 1200,
      "url": "https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/35/a7/37/35a737c9-b050-8cd3-b18d-14f75437899e/873.jpg/{w}x{h}bb.jpeg"
    },
      "trackCount": 6
    }
    },
      */

/*
      {
        "record": {
        "id": "709397994",
        "type": "albums",
        "href": "/v1/catalog/us/albums/709397994",
        "attributes": {
        "artwork": {
        "width": 1417,
        "height": 1417,
        "url": "https://is3-ssl.mzstatic.com/image/thumb/Music6/v4/26/ff/56/26ff56cb-460c-367a-a3cc-ca01b1b59af4/5099969756153_1417x1417_300dpi.jpg/{w}x{h}bb.jpeg",
        "bgColor": "ffffff",
        "textColor1": "030303",
        "textColor2": "2f2f2f",
        "textColor3": "353535",
        "textColor4": "595959"
      },
        "artistName": "Röyksopp",
        "isSingle": false,
        "url": "https://music.apple.com/us/album/junior/709397994",
        "isComplete": false,
        "genreNames": [
        "Dance",
        "Music",
        "Electronic",
        "Rock",
        "Jazz",
        "Bop",
        "Downtempo"
        ],
        "trackCount": 12,
        "isMasteredForItunes": false,
        "releaseDate": "2009-03-20",
        "name": "Junior",
        "recordLabel": "EMI France",
        "copyright": "℗  2009 Parlophone Music France"
      }
      },
        "time": "1560012881.302"
      }
      */
