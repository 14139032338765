/*
recents shelf, composed of
- added
- recently played from AM
- recently played from AM Shelf playlist

- on new install, simply load those synchronously and put them in the shelf.
- when new record is added, add to beginning (and call AM API to add)
- when new record is played, add to beginning (and call AM API to add to playlist)

- on relaunch (or reload), shelf order is stored on disk and is rehydrated
    and instantly displayed
    - meanwhile the recently added + 2 recently played lists are fetched in order.
      - for each page of records, call (1) addRecordsToMap and (2) addRecordsToShelf

- addRecordsToShelf needs to not add dupes.
- records are ordered via the .index property on record. so when a new record is
  added, first check to see if it is already there. if not, put it at the beginning
- if you are adding multiple records at a time, paged or not, you must
  keep track of the cursor as you do, either locally or in redux.
  this is because records come back in most-recent-first order (1-10,11-20 etc.)
  so they must be given indexes that correspond to the time they were first seen
  by shelf.
- the order of retrieving different groupings of records (recently added,
  recently played etc.) MUST be the reverse of how you are showing them in the list.
  so retrieve addeds first, then recently played, because recently played should
  be at the top.
- always reset cursor to zero before inserting records because we are always
  pushing into the TOP of the list.
- while adding library records to the top of the list (ADD_RECORDS_TO_SHELF), check to see
  if the record is already in the shelf. if it is, move to top. (popExistingToTop=true)
- while adding single new records to the top of the list based on user actions (play, add button),
  the logic is different. if there is an existing record in the shelf, we want to update it
  with index=0 (popExistingToTop=true). otherwise, set cursor to zero and add to beginning
- when adding a recently played, instead of removing any existing identical record,
  instead change that record's index to the current cursor value (0).

 */
import {
  areRecordsTheSame,
  bestRecord,
  browserCountryCode,
  cleanName,
  dedupeRecords,
  excludedLabels,
  fetchJson,
  fetchWithAuth,
  isPlaylist,
  jlog,
  MUSICBRAINZ,
  processEndpoint,
  slugForArtistNameAndAlbumName,
  slugForRecord
} from "../helpers";
import _ from "lodash";
import {
  ADD_PLAYLISTS_TO_SHELF,
  ADD_RECORD_TO_LABEL_TO_ARTIST_MAP,
  ADD_RECORDS_TO_MAP,
  ADD_RECORDS_TO_MUSICBRAINZ_ONLY_MAP,
  ADD_RECORDS_TO_SHELF,
  CLEAR_USER_DATA,
  DETAILED_INFO_LOADED_FOR_RECORD,
  DISCOGRAPHY_LOADED_FOR_RECORD,
  DONE_DOING_RECENTLY_PLAYED_PLAYLIST_STUFF,
  DONE_LOADING_RECENTS_FROM_APPLE_MUSIC,
  EXTERNAL_SOURCE_APPLE_MUSIC_NEW_RELEASES,
  EXTERNAL_SOURCE_DETAILED_INFO_FOR_RECORD,
  EXTERNAL_SOURCE_DISCOGRAPHY_FOR_RECORD,
  EXTERNAL_SOURCE_LABEL_RECORDS,
  EXTERNAL_SOURCE_LOADED,
  EXTERNAL_SOURCE_LOADING_FAILED,
  EXTERNAL_SOURCE_SPINITRON_TOP_50,
  HARD_REFRESH_SHELF,
  INSERT_RECORDS_ON_TOP_OF_SHELF,
  LOADING_EXTERNAL_SOURCE,
  MOVE_RECORD_TO_TOP_OF_SHELF,
  RATINGS_LOADED,
  RECORD_DETAILS_LOADED_BASED_ON_SLUG,
  RECORD_SELECTED,
  REMOVE_RECORD_FROM_RECORD_STORY_MAP,
  REMOVE_RECORD_FROM_SHELF,
  SAVE_RECENTLY_PLAYED_PLAYLIST_RECORDS,
  SET_LOADING_STEP,
  SET_RECENTLY_PLAYED_PLAYLIST_ID,
  SET_SHELF_CURSOR,
  SET_SHELF_SORT_OPTION,
  SOURCE_RECENTLY_ADDED_AM,
  SOURCE_RECENTLY_PLAYED_AM,
  SOURCE_RECENTLY_PLAYED_HERE,
  STARTED_DOING_RECENTLY_PLAYED_PLAYLIST_STUFF,
  STARTED_LOADING_RECENTS_FROM_APPLE_MUSIC,
  TRACKS_LOADED_FOR_RECORD,
  TURN_BIG_RECORD_TO_SIDE,
  UPDATE_RATING_MAP,
  PLAYLISTS_LOADED,
  TRACK_ADDED_TO_PLAYLIST
} from "../reducers/record-reducer";
import { store } from "../index";

import {
  flash,
  scrollYTo,
  setNormalPalette,
  setPaletteBasedOnRecord
} from "./ui-actions";
import {
  CATALOG_ID_APPLE_MUSIC_NEW_RELEASES,
  CATALOG_ID_LABEL_NEW_RELEASES,
  CATALOG_ID_SPINITRON
} from "../reducers/catalogs-reducer";
import { constant, Constants, EndPoints, flag, Flags } from "../helpers/flags";
import { SELECTION_ANIMATION_DURATION } from "../components/SelectionAnimationOverlay/selection-animation-overlay";
import {
  CLEAR_ME_ALBUMS_COUNT,
  FLASH,
  INCREMENT_ME_ALBUMS_COUNT
} from "../reducers/ui-reducer";
import orbitStore, { orbitJsonApiStore } from "../orbit/orbit-coordinator";
import moment from "moment";

export const RECENTLY_PLAYED_PLAYLIST_NAME = "shelf.fm";

const srcs = () => ({
  recentlyAdded: constant(Constants.dataLoadingProfile).recentlyAdded,
  recentlyPlayedAM: constant(Constants.dataLoadingProfile).recentlyPlayedAM,
  recentlyPlayedHere: constant(Constants.dataLoadingProfile).recentlyPlayedHere,
  libraryAlbums: constant(Constants.dataLoadingProfile).libraryAlbums,
  labelRecordsToSend: constant(Constants.dataLoadingProfile).labelRecordsToSend
});

/*
scenarios:
- called from fetch: popToTop is false, index is set
- play from recentlyplayed: poptoTop true, existing exists
- play from other shelf: poptotop true
 */

/* new 2025 way
- we just want recently added and recently played to reflect directly
  what's on apple music
- don't pop to top.
- don't add locally.
- just fetch what's in the endpoint and put it in the shelf.
 */

export function addUpdateRecordViaOrbit(
  record,
  source,
  popToTop = false,
  addToRecentlyPlayed = false
) {
  record.type = "album";
  const minIndex = orbitStore.cache
    .query(q => q.findRecords("album"))
    .reduce(
      (acc, r) =>
        acc === undefined || acc > r.attributes.index
          ? r.attributes.index
          : acc,
      undefined
    );

  try {
    if (popToTop) {
      record.attributes = {
        ...record.attributes,
        index: minIndex - 1
      };
    }
    // if (addToRecentlyPlayed) {
    //   const interval = 1000 * 60 * 60 * 24 * 60;
    //   if (new Date().getTime()-record.attributes.playedAt<interval) {
    //     record.attributes = {
    //       ...record.attributes,
    //       playedAt: new Date().getTime()
    //     };
    //   }
    // }

    const existingRecord = orbitStore.cache.query(q =>
      q.findRecord({ type: "album", id: record.id })
    );

    if (existingRecord) {
      const recordAttributes = { ...record.attributes }; // respect existing position
      record.attributes = {
        ...existingRecord.attributes,
        ...recordAttributes
      };
      // if (popToTop) {
      //   record.attributes = {
      //     ...record.attributes,
      //     index: minIndex - 1
      //   };
      // }
      orbitStore.update(t => {
        record.attributes = {
          ...record.attributes,
          source
        };
        return [t.updateRecord(record)];
      });
      return;
    }
  } catch (err) {}

  // no existing record found
  orbitStore.update(t => {
    // if (popToTop) {
    //   record.attributes = {
    //     ...record.attributes,
    //     index: minIndex - 1
    //   };
    // }

    // if (!record.attributes.dateAdded) {
    //   record.attributes = {
    //     ...record.attributes,
    //     //dateAdded: moment().format("YYYY-MM-DD")
    //   };
    // }

    record.attributes = {
      ...record.attributes,
      source
    };
    return [t.addRecord(record)];
  });
}

function orbitRecordSetProcessor(records, source, addToRecentlyPlayed = false) {
  records &&
    records
      .filter(record => ["library-albums", "albums"].includes(record.type))
      .forEach(record => {
        addUpdateRecordViaOrbit(record, source, false, addToRecentlyPlayed);
      });
}

export function loadRecentlyAddedIntoOrbit() {
  return processEndpoint(
    EndPoints.recentlyAdded,
    null,
    (records, endpoint) => orbitRecordSetProcessor(records, endpoint),
    srcs().recentlyAdded
  );
}

export async function loadRecentlyPlayedIntoOrbit() {
  let recentlyPlayed = [];
//  return;
  await processEndpoint(
    EndPoints.recentlyPlayed,
    null,
    records => {
      if (records) recentlyPlayed = [...recentlyPlayed, ...records];
    },
    srcs().recentlyPlayedAM
  );

  orbitRecordSetProcessor(
    recentlyPlayed.map((record, index) => {
      const attr = { ...record.attributes, index };
      const recordCopy = { ...record };
      recordCopy.attributes = attr;
      return recordCopy;
    }),
    EndPoints.recentlyPlayed,
    true
  );
}

export async function loadOrbitUserData() {
  console.log("loadOrbitUserData");
  orbitStore.query(q => q.findRecords("playlist"));
  loadRecentlyAddedIntoOrbit();
  loadRecentlyPlayedIntoOrbit();

  // processEndpoint(
  //   EndPoints.libraryAlbums,
  //   null,
  //   (records, endpoint) => orbitRecordSetProcessor(records, endpoint),
  //   srcs().libraryAlbums
  // );
}

export function loadUserData(forceNow = false) {
  return function(dispatch, getState) {
    const state = getState();
    const timeout =
      state.lightweightRecord.userDataLoaded && !forceNow ? 1000 * 60 * 5 : 0; // 5 minutes
    jlog({ userDataLoaded: state.lightweightRecord.userDataLoaded });
    dispatch(setLoadingStep(null));
    console.log("loadUserData");

    if (flag(Flags.loadUserData)) {
      loadOrbitUserData();
      console.log("loading new releases");

      dispatch(loadNewReleases());

      // dispatch(startedLoadingRecentsFromAppleMusic());
      // dispatch(doRecentlyPlayedPlaylistStuff());
      console.log("loading userData after timeout (s)", timeout / 1000);
      setTimeout(() => {
        //dispatch(loadAlbums());
        // dispatch(startedLoadingRecentsFromAppleMusic());
        //dispatch(doRecentlyPlayedPlaylistStuff());
        // dispatch(loadRecentlyPlayedFromAppleMusic());
        dispatch(setLoadingStep(null));
        dispatch(doneLoadingRecentsFromAppleMusic());
      }, timeout);
    } else {
      console.log("Flags.loadUserData is false skipping user data load");
    }
  };
}
//
// export function loadRecentlyPlayedFromAppleMusic() {
//   return async function(dispatch, getState) {
//     dispatch(setLoadingStep("recently played"));
//     const state = getState();
//     let batch = 0;
//     let count = 0;
//     srcs().recentlyPlayedAM &&
//       (await processEndpoint(
//         "me/recent/played",
//         null,
//         records => {
//           if (records) count = count + records.length;
//           dispatch(setLoadingStep("recently played", count));
//           dispatch(
//             addRecordsToShelf(state.record.recentsShelfIndex, records, false, {
//               source: SOURCE_RECENTLY_PLAYED_AM,
//               batch
//             })
//           );
//           dispatch(
//             addRecordsToShelf(
//               state.record.recentlyPlayedShelfIndex,
//               records,
//               false,
//               {
//                 source: SOURCE_RECENTLY_PLAYED_AM,
//                 batch
//               }
//             )
//           );
//           batch = batch + 1;
//         },
//         srcs().recentlyPlayedAM
//       ));
//     dispatch(
//       setShelfCursor(
//         state.record.recentsShelfIndex,
//         state.lightweightRecord.userDataLoaded ? 0 : 20000
//       )
//     );
//     const recentsShelf = state.record.shelves[state.record.recentsShelfIndex];
//     const timeout = recentsShelf && recentsShelf.records.length > 0 ? 10000 : 0;
//     dispatch(setLoadingStep(null));
//     dispatch(loadRecentlyAddedFromAppleMusic());
//     // setTimeout(() => dispatch(loadRecentlyAddedFromAppleMusic()), timeout);
//     // setTimeout(() => {
//     //   dispatch(
//     //     loadRatingsForShelf(0 + state.record.recentlyAddedShelfIndex, () =>
//     //       dispatch(doneLoadingRecentsFromAppleMusic())
//     //     )
//     //   );
//     // }, 5000);
//   };
// }
//
// export function loadRecentlyAddedFromAppleMusic() {
//   return async function(dispatch, getState) {
//     const state = getState();
//     dispatch(setLoadingStep("recently added"));
//
//     // recently added first
//     let batch = 0;
//     let count = 0;
//
//     srcs().recentlyAdded &&
//       (await processEndpoint(
//         "me/library/recently-added",
//         null,
//         records => {
//           if (records) count = count + records.length;
//           dispatch(setLoadingStep("recently added", count));
//           dispatch(
//             addRecordsToShelf(state.record.recentsShelfIndex, records, false, {
//               source: SOURCE_RECENTLY_ADDED_AM,
//               batch
//             })
//           );
//           dispatch(
//             addRecordsToShelf(
//               state.record.recentlyAddedShelfIndex,
//               records,
//               false,
//               {
//                 source: SOURCE_RECENTLY_ADDED_AM,
//                 batch
//               }
//             )
//           );
//
//           batch = batch + 1;
//         },
//         srcs().recentlyAdded
//       ));
//     dispatch(setLoadingStep(null));
//     dispatch(doneLoadingRecentsFromAppleMusic());
//
//     // dispatch(
//     //   loadRatingsForShelf(state.record.recentsShelfIndex, () => {
//     //     dispatch(doneLoadingRecentsFromAppleMusic());
//     //   })
//     // );
//   };
// }
//
// export function addPlaylistsToShelf(shelfIndex, playlists = []) {
//   return async function(dispatch, getState) {
//     dispatch({
//       type: ADD_PLAYLISTS_TO_SHELF,
//       playlists,
//       shelfIndex
//     });
//   };
// }

export function loadAlbums() {
  return async function(dispatch, getState) {
    const state = getState();
    dispatch(setLoadingStep("library records"));

    const meAlbumNames = ["1977", "Afrobeat...No Go Die!", "Agora"];
    // recently added first
    let batch = 0;
    let count = 0;
    dispatch({ type: CLEAR_ME_ALBUMS_COUNT });
    srcs().libraryAlbums &&
      (await processEndpoint(
        "me/library/albums",
        null,
        records => {
          if (records) count = count + records.length;
          dispatch(setLoadingStep("library records", count));
          records &&
            records.forEach(record => {
              if (meAlbumNames.includes(record.attributes.name))
                dispatch({ type: INCREMENT_ME_ALBUMS_COUNT });
            });
          dispatch(
            addRecordsToShelf(state.record.libraryRecordsShelfIndex, records)
          );
        },
        srcs().libraryAlbums
      ));
    dispatch(setLoadingStep(null));
  };
}

export function loadNewReleases() {
  return function(dispatch, getState) {
    const loggedIn = getState().authentication.loggedIn;
    if (!loggedIn) return;
    dispatch(setLoadingStep("new releases"));
    dispatch(loadLabelRecords());
    dispatch(loadAppleMusicRecommendedAlbums());
  };
}

export function loadPlaylists() {
  // playlists
  // orbitStore.query(q => q.findRecords("playlist"))

  // albums
  processEndpoint(
    "me/library/albums",
    null,
    records => {
      records &&
        records.forEach(record => {
          orbitStore.update(t => {
            return [t.addRecord(record)];
          });
        });
    },
    srcs().libraryAlbums
  );
}

//
// export function loadPlaylists() {
//   return async function (dispatch, getState) {
//     const state = getState();
//     const {playlistsShelfIndex} = state.record;
//     const results = await fetchWithAuth("me/library/playlists", {
//       limit: 100
//     });
//     if (results) {
//       const playlists = results.data
//       .filter(p => p.attributes.canEdit === true)
//       .filter(p => p.attributes.name && p.attributes.name.trim().length > 0);
//       dispatch({type: PLAYLISTS_LOADED, playlists: playlists});
//       playlists.forEach(playlist => {
//         orbitStore.update(t => {
//           playlist.type="playlist"
//           return [
//             t.addRecord(playlist)
//           ]
//         })
//       })
//       // dispatch(addPlaylistsToShelf(playlistsShelfIndex, playlists));
//     }
//   };
// }

export function addTrackToPlaylist(playlist, track) {
  return async function(dispatch) {
    const body = {
      data: [
        {
          id: track.id,
          type: "songs"
        }
      ]
    };
    const result = await fetchWithAuth(
      `me/library/playlists/${playlist.id}/tracks`,
      body,
      false,
      "post",
      false,
      true
    );
    dispatch({ type: TRACK_ADDED_TO_PLAYLIST, playlist, track });
    dispatch(
      flash(`added '${track.attributes.name}' to ${playlist.attributes.name}`)
    );
  };
}

//https://music.apple.com/us/playlist/pitchforks-best-new-music/pl.9107845577c24fe3be7193c55d7864b0
export function loadPlaylistRecordsIntoShelf(playlistId, shelfId) {
  return async function(dispatch, getState) {
    dispatch(clearShelf(shelfId));
    const endpoint = `catalog/us/playlists/${playlistId}/tracks`;

    const results = await fetchWithAuth(endpoint);
    const records = [];

    for (let track of results.data) {
      if (!track || !track.attributes) return;
      const matches = /\/([0-9]+)\?/.exec(track.attributes.url);
      if (!matches || matches.length < 2) {
        continue;
      }
      const id = matches[1];
      let r = {
        id,
        type: "albums",
        attributes: { ...track.attributes, name: track.attributes.albumName }
      };
      records.push(r);
    }
    dispatch(addRecordsToShelf(shelfId, records, false));
  };
}

export async function catalogIdForRecord(record, state) {
  if (!record) return null;
  const _state = state || store.getState();
  const id = record.id;
  if (id && !id.includes(".")) return id; // it's a catalog id
  const { slugToRecordMap } = _state.record;
  let catalogAlbum =
    slugToRecordMap[
      slugForArtistNameAndAlbumName(
        record.attributes.artistName,
        record.attributes.name
      )
    ] ||
    (await getCatalogAlbumWithArtistNameAndAlbumName(
      record.attributes.artistName,
      record.attributes.name
    ));
  return catalogAlbum ? catalogAlbum.id : record.id;
}
//
// export function doRecentlyPlayedPlaylistStuff() {
//   return async function(dispatch, getState) {
//     const state = getState();
//     const {
//       recentsShelfIndex,
//       recentlyPlayedShelfIndex,
//       slugToRecordMap,
//       playlistsShelfIndex
//     } = state.record;
//     dispatch({ type: STARTED_DOING_RECENTLY_PLAYED_PLAYLIST_STUFF });
//     // get playlists
//     const playlists = await fetchWithAuth("me/library/playlists", {
//       limit: 100
//     });
//     dispatch(addPlaylistsToShelf(playlistsShelfIndex, playlists.data));
//
//     // find one named "shelf.fm"
//     const shelfFmPlayLists =
//       playlists.data &&
//       playlists.data.filter(
//         playlist => playlist.attributes.name === RECENTLY_PLAYED_PLAYLIST_NAME
//       );
//     let shelfFmPlaylist = null;
//     // if it exists, set that as the played songs playlist id
//     if (shelfFmPlayLists && shelfFmPlayLists.length > 0) {
//       const sortedShelfFmPlayLists = _.sortBy(
//         shelfFmPlayLists,
//         "attributes.dateAdded"
//       );
//
//       shelfFmPlaylist = sortedShelfFmPlayLists[0];
//     } else {
//       // if it doesn't exist, create and use response id as played songs playlist (set in redux)
//       const shelfFmPlaylists = await fetchWithAuth(
//         "me/library/playlists",
//         {
//           attributes: {
//             name: RECENTLY_PLAYED_PLAYLIST_NAME,
//             description: `Songs you played on shelf.fm`
//           }
//         },
//         false,
//         "post",
//         true, // wants response,
//         true
//       );
//       if (shelfFmPlaylists !== undefined && shelfFmPlaylists.length > 0)
//         shelfFmPlaylist = shelfFmPlayLists[0];
//     }
//     // get last x albums from played songs playlist
//     //try {
//     let libraryTracks = [];
//     let count = 0;
//     if (shelfFmPlaylist) {
//       dispatch(setRecentlyPlayedPlaylistId(shelfFmPlaylist.id));
//       srcs().recentlyPlayedHere &&
//         (await processEndpoint(
//           `me/library/playlists/${shelfFmPlaylist.id}/tracks`,
//           { include: "albums" },
//           tracks => {
//             if (tracks) count = count + tracks.length;
//             dispatch(setLoadingStep("recently played", count));
//             if (tracks) libraryTracks = libraryTracks.concat(tracks);
//           },
//           10000
//         ));
//       const records = [];
//       jlog({ playlistlibraryTracks: libraryTracks.length });
//       for (let track of libraryTracks) {
//         const catalogId = _.get(track, "attributes.playParams.catalogId", null);
//         if (!catalogId) continue;
//         // const catalogTrack = await fetchWithAuth(
//         //   `/v1/catalog/us/songs/${catalogId}`,
//         //   {
//         //     include: "albums",
//         //   }
//         // );
//         // let album = _.get(
//         //   track,
//         //   "data[0].relationships.albums.data[0]",
//         //   null
//         // );
//         let album = {};
//         album.trackId = catalogId;
//         // album.catalogId = catalogId;
//         // album.originalId = catalogId;
//         // album.href = `/v1/catalog/us/albums/${catalogId}`;
//         album.type = "albums";
//         album.attributes = {
//           releaseDate: _.get(track, "attributes.releaseDate"),
//           name: _.get(track, "attributes.albumName"),
//           artistName: _.get(track, "attributes.artistName"),
//           artwork: _.get(track, "attributes.artwork")
//         };
//         // let albumWithTrackReleaseDate = {
//         //   ...album,
//         //   attributes,
//         // };
//         records.push(album);
//       }
//       const slicedRecords = _.slice(
//         [...records].reverse(),
//         0,
//         srcs().recentlyPlayedHere
//       );
//       const processedRecords = slicedRecords;
//       // for (var record of slicedRecords) {
//       //   if (Object.keys(record.attributes || {}).length < 2) {
//       //     let amRecord =
//       //       slugToRecordMap[
//       //         slugForArtistNameAndAlbumName(
//       //           record.track.attributes.artistName,
//       //           record.track.attributes.albumName
//       //         )
//       //       ] ||
//       //       (await getCatalogAlbumWithArtistNameAndAlbumName(
//       //         record.track.attributes.artistName,
//       //         record.track.attributes.albumName
//       //       ));
//       //     if (!amRecord) {
//       //       console.log(
//       //         "playlist record can't be found",
//       //         record.track.albumName
//       //       );
//       //       continue;
//       //     } else record = { ...record, ...amRecord };
//       //   }
//       //   processedRecords.push(record);
//       //dispatch(resetShelfCursor(recentsShelfIndex));
//       // }
//       const dedupedRecords = dedupeRecords(processedRecords);
//       dispatch(
//         addRecordsToShelf(recentsShelfIndex, dedupedRecords, false, {
//           source: SOURCE_RECENTLY_PLAYED_HERE,
//           batch: 1
//         })
//       );
//       dispatch(
//         addRecordsToShelf(recentlyPlayedShelfIndex, dedupedRecords, false, {
//           source: SOURCE_RECENTLY_PLAYED_HERE,
//           batch: 1
//         })
//       );
//
//       dispatch(saveRecentlyPlayedPlaylistRecords(dedupedRecords));
//     }
//     // } catch (error) {
//     //   jlog({ error: error.message });
//     //   dispatch({ type: ERROR, message: error.message });
//     // }
//     dispatch({ type: DONE_DOING_RECENTLY_PLAYED_PLAYLIST_STUFF });
//     const shelfLength = state.record.shelves[recentsShelfIndex].records.length;
//     dispatch(
//       setShelfCursor(
//         recentsShelfIndex,
//         state.lightweightRecord.userDataLoaded ? 0 : 10000
//       )
//     );
//     dispatch(loadRecentlyPlayedFromAppleMusic());
//   };
// }
//
// function saveRecentlyPlayedPlaylistRecords(records) {
//   return function(dispatch) {
//     dispatch({ type: SAVE_RECENTLY_PLAYED_PLAYLIST_RECORDS, records });
//   };
// }

export async function fetchObjectWithHref(href, params) {
  if (!href) return null;
  const result = await fetchWithAuth(href, params);
  return _.get(result, "data[0]");
}
//
// async function getCatalogAlbum(record) {
//   // uri = URI.parse('https://api.music.apple.com/v1/catalog/us/search')
//   // params = {
//   //   term:"#{artist} #{album}",
//   //   types:"artists,albums",
//   //   include:"albums"
//   // }
//   if (!record.attributes) return Promise.resolve({});
//   return await getCatalogAlbumWithArtistNameAndAlbumName(
//     record.attributes.artistName,
//     record.attributes.name
//   );
// }

// async function getCatalogAlbumWithArtistNameAndAlbumName(
//     artistName,
//     albumName
// ) {
//   const url = generateUrl(`${base_url}/apple_music_album`, {
//     artistName:encodeURIComponent(artistName),albumName:encodeURIComponent(albumName)
//   }, false);
//   const results = await fetch(url);
//   return results
// }

export async function getCatalogAlbumWithArtistNameAndAlbumName(
  artistName,
  albumName
) {
  if (!artistName || !albumName) return null;
  const results = await fetchWithAuth("catalog/us/search", {
    term: `${cleanName(artistName)} ${cleanName(albumName)}`
  });
  let album = _.get(results, "results.albums.data[0]", null);

  const cleanName1 = cleanName(albumName).toLowerCase();
  const cleanName2 = cleanName(_.get(album, "attributes.name")).toLowerCase();
  if (cleanName1 !== cleanName2) return null;
  if (album) return album;
  else {
    const results2 = await fetchWithAuth("catalog/us/search", {
      term: `${cleanName(albumName)}`
    });
    return _.get(results2, "results.albums.data[0]", null);
  }
}

export function addRecordsToMap(records) {
  // must also fetch catalog ids
  return async function(dispatch, getState) {
    const state = getState();
    for (const record of records) {
      if (state.record.idToRecordMap[record.id]) continue;
      dispatch(addRecordToLabelToArtistMap(record));
      dispatch({ type: ADD_RECORDS_TO_MAP, records: [record] });
      //
      // if (record.type === "library-albums") {
      //   const catalogAlbum = await getCatalogAlbum(record);
      //   if (!catalogAlbum) return;
      //   dispatch(addRecordToLabelToArtistMap(catalogAlbum));
      //   dispatch({ type: ADD_RECORDS_TO_MAP, records: [catalogAlbum] });
      //   const newRecord = { ...record, ...{ catalogAlbumId: catalogAlbum.id } };
      //   dispatch({
      //     type: ADD_RECORDS_TO_MAP,
      //     records: [newRecord]
      //   });
      // } else {
      //   const newRecord = { ...record, ...{ catalogAlbumId: record.id } };
      //   dispatch(addRecordToLabelToArtistMap(newRecord));
      //   dispatch({ type: ADD_RECORDS_TO_MAP, records: [newRecord] });
      // }
    }
  };
}

export function addRecordsToShelf(
  shelfIndex,
  data,
  popExistingToTop = false,
  extra = {}
) {
  return function(dispatch, getState) {
    const state = getState();
    const shelf = state.record.shelves[shelfIndex];
    if (!data || data.length === 0) return;
    const recordsToAdd = data
      .filter(
        item =>
          item &&
          (item.type === "albums" ||
            item.type === "library-albums" ||
            _.get(item, "attributes.musicbrainzId")) &&
          item.attributes
      )
      .filter(record => {
        let ret = true;
        for (let r of [...(shelf.records || [])]) {
          if (areRecordsTheSame(r, record)) {
            if (popExistingToTop) {
              dispatch(removeRecordFromShelf(shelfIndex, r));
              ret = true;
              break;
            } else {
              ret = false;
              break;
            }
          }
        }
        return ret;
      })
      .map((r, i) => {
        const _extra = { ...extra };
        _extra.withinBatchIndex = i;
        return { ...r, extra: { ...r.extra, ..._extra } };
      });
    //dispatch(addRecordsToMap(recordsToAdd));

    dispatch({
      type: ADD_RECORDS_TO_SHELF,
      records: dedupeRecords(recordsToAdd),
      shelfIndex
    });
  };
}

export function rateRecord(record, rating) {
  return async function(dispatch, getState) {
    const state = getState();
    const id = await catalogIdForRecord(record, state);
    dispatch({
      type: UPDATE_RATING_MAP,
      record,
      rating
    });
    id &&
      fetchWithAuth(
        `me/ratings/albums/${id}`,
        {
          type: "rating",
          attributes: {
            value: rating
          }
        },
        false,
        "put",
        false,
        true
      ).then(() => {
        dispatch({
          type: UPDATE_RATING_MAP,
          record,
          rating
        });
      });
  };
}

export function deleteRecord(shelfIndex, record) {
  return function(dispatch) {
    dispatch({ type: "DELETING_RECORD" });
    dispatch(rateRecord(record, -1));
  };
}

export function addRecord(shelfIndexes, record) {
  return function(dispatch, getState) {
    const state = getState();
    addUpdateRecordViaOrbit(record, EndPoints.recentlyAdded);
    // for (var shelfIndex of shelfIndexes) {
    //   dispatch(insertRecordIntoTopOfShelf(shelfIndex, record));
    // }
    // const { libraryRecordsShelfIndex } = state.record;
    // const shelf = state.record.shelves[libraryRecordsShelfIndex];
    // const addedRecords = shelf.records;
    // const isAdded = addedRecords.reduce(
    //   (acc, addedRecord) => areRecordsTheSame(record, addedRecord) || acc,
    //   false
    // );
    // if (isAdded) {
    //   dispatch(rateRecord(record, 1));
    // } else {
    //   fetchWithAuth(`me/library`, { "ids[albums]": record.id }, false, "post")
    //     .then(() => {
    //       setTimeout(() => {
    //         dispatch(rateRecord(record, 1));
    //         //dispatch(refreshRecentlyAddedTop('recently-added', 1))
    //       }, 20000);
    //     })
    //     .catch(() => {
    //       alert(`unable to add (${record.id})`);
    //     });
    // }
  };
}
//
// export function moveRecordToTopOfShelf(shelfIndex, record, source) {
//   return function(dispatch, getState) {
//     dispatch(removeRecordFromShelf(shelfIndex, record));
//     dispatch(resetShelfCursor(shelfIndex));
//     dispatch(addRecordsToShelf(shelfIndex, [record], true, source));
//     dispatch({ type: MOVE_RECORD_TO_TOP_OF_SHELF, shelfIndex, record, source });
//   };
// }

export function hardRefreshShelf(shelfIndex) {
  return function(dispatch) {
    dispatch(clearShelf(shelfIndex));
    console.log("calling loadUserData again ...");
    dispatch(loadUserData(true));
  };
}

export function clearShelf(shelfIndex) {
  return function(dispatch) {
    dispatch({ type: HARD_REFRESH_SHELF, shelfIndex: shelfIndex });
  };
}

export function insertRecordIntoTopOfShelf(shelfIndex, record) {
  return function(dispatch, getState) {
    if (!record) return;

    dispatch(resetShelfCursor(shelfIndex));
    dispatch(addRecordsToShelf(shelfIndex, [record], true));
  };
}

export function resetShelfCursor(shelfIndex) {
  return {
    type: SET_SHELF_CURSOR,
    shelfIndex,
    cursor: 0
  };
}

export function setShelfCursor(shelfIndex, cursor) {
  return {
    type: SET_SHELF_CURSOR,
    shelfIndex,
    cursor
  };
}

export function removeRecordFromShelf(shelfIndex, record) {
  return function(dispatch, getState) {
    dispatch({ type: REMOVE_RECORD_FROM_SHELF, shelfIndex, record });
  };
}
//
// export function conditionallyAddRecordToRecentlyPlayedPlaylist(record) {
//   return async function(dispatch, getState) {
//     if (!record) return;
//     const state = getState();
//     const {
//       recentlyPlayedPlaylistId,
//       recentlyPlayedPlaylistRecords
//     } = state.record;
//     let albums;
//     const recentDupe = recentlyPlayedPlaylistRecords.reduce(
//       (acc, r) => acc || areRecordsTheSame(r, record),
//       false
//     );
//     if (recentDupe) return;
//     albums = await fetchWithAuth(`me/library/albums/${record.id}`);
//     if (albums === null || albums.errors)
//       albums = await fetchWithAuth(`catalog/us/albums/${record.id}`);
//     const firstTrackId = _.get(
//       albums,
//       "data[0].relationships.tracks.data[0].id"
//     );
//     if (firstTrackId) {
//       const _body = {
//         data: [
//           {
//             id: firstTrackId,
//             type: "songs"
//           }
//         ]
//       };
//       const result = await fetchWithAuth(
//         `me/library/playlists/${recentlyPlayedPlaylistId}/tracks`,
//         _body,
//         false,
//         "post",
//         false,
//         true
//       );
//     }
//     dispatch({
//       type: "RECORD_ADDED_TO_RECENTLY_PLAYED_PLAYLIST",
//       record: record.attributes.name
//     });
//   };
// }

// export function setRecentlyPlayedPlaylistId(playlistId) {
//   return { type: SET_RECENTLY_PLAYED_PLAYLIST_ID, playlistId };
// }
//
// export function startedLoadingRecentsFromAppleMusic() {
//   return { type: STARTED_LOADING_RECENTS_FROM_APPLE_MUSIC };
// }

function addRecordToLabelToArtistMap(record) {
  return { type: ADD_RECORD_TO_LABEL_TO_ARTIST_MAP, record };
}

export function doneLoadingRecentsFromAppleMusic() {
  return function(dispatch, getState) {
    dispatch({ type: DONE_LOADING_RECENTS_FROM_APPLE_MUSIC });
  };
}

async function slugForCatalogId(id) {
  const result = await fetchWithAuth(`/catalog/us/albums/${id}`, {}, false);
  const record = _.get(result, "data[0]");
  return slugForRecord(record);
}

/*
{
"ratings": [
{
"id": "1207905012",
"type": "ratings",
"href": "/v1/me/ratings/albums/1207905012",
"attributes": {
"value": 1
}
},
 */
export function ratingsLoaded(ratings) {
  return async function(dispatch, getState) {
    jlog({ ratings });
    let slugRatings = {};
    for (let rating of ratings) {
      const slug = await slugForCatalogId(rating.id);
      slugRatings = { ...slugRatings, [slug]: rating.attributes.value };
    }
    dispatch({ type: RATINGS_LOADED, slugRatings });
  };
}

export function loadRatingsForRecords(records, callback) {
  return function(dispatch, getState) {
    const state = getState();
    dispatch(setLoadingStep("ratings"));

    if (records && records.length > 0) {
      const ids = _.join(records.map(r => r.id), ",");
      jlog({ ids });
      fetchWithAuth("me/ratings/albums", { ids }).then(response => {
        dispatch(setLoadingStep(null));
        response && dispatch(ratingsLoaded(response.data));
        callback && callback();
      });
    } else {
      callback && callback();
    }
  };
}

// export function loadRatingsForShelf(shelfIndex, callback) {
//   return function(dispatch, getState) {
//     const state = getState();
//     const shelf = state.record.shelves[shelfIndex];
//     if (shelf) {
//       const { records } = shelf;
//       dispatch(loadRatingsForRecords(records, callback));
//     } else {
//       callback();
//     }
//   };
// }

export function setLoadingStep(step, count) {
  return function(dispatch, getState) {
    dispatch({ type: SET_LOADING_STEP, step, count });
  };
}

export function setShelfSortOption(shelfIndex, sortOptionIndex) {
  return { type: SET_SHELF_SORT_OPTION, shelfIndex, sortOptionIndex };
}

function parseOutNewReleases(response) {
  return function(dispatch, getState) {
    let newReleases = null;
    console.log("parseOutNewReleases a");
    if (!response || !response.data) return null;
    console.log("parseOutNewReleases b",response.data);
    for (var elem of response.data) {
      const title = _.get(elem, "attributes.title.stringForDisplay");
      if (title === "New Releases for You") {
        console.log("parseOutNewReleases c, new releases found");

        newReleases = _.get(elem, "relationships.contents.data");
        break;
      }
    }
    if (!newReleases) return null;
    console.log("parseOutNewReleases c");

    //dispatch(clearShelf(getState().record.labelRecordsShelfIndex));
    dispatch(
      addRecordsToShelf(
        getState().record.labelRecordsShelfIndex,
        newReleases,
        false,
        { catalogSource: CATALOG_ID_APPLE_MUSIC_NEW_RELEASES }
      )
    );
    dispatch(setLoadingStep(null));
    dispatch(
      externalSourceLoaded(
        EXTERNAL_SOURCE_APPLE_MUSIC_NEW_RELEASES,
        getState().record.labelRecordsShelfIndex,
        newReleases
      )
    );
  };
}
//
// function parseOutRecommendations(response) {
//   return function(dispatch, getState) {
//     const records = [];
//     for (var bucket of response.data) {
//       const title = _.get(bucket, "attributes.title.stringForDisplay");
//       if (title === "New Releases") continue;
//       const resourceTypes = _.get(bucket, "attributes.resourceTypes");
//       if (resourceTypes.includes("albums")) {
//         const resources = _.get(bucket, "relationships.contents.data");
//         for (var resource of resources) {
//           if (resource.type === "albums") {
//             resource.source = title;
//             records.push(resource);
//           }
//         }
//       }
//     }
//     dispatch(clearShelf(getState().record.recommendedShelfIndex));
//     dispatch(
//       addRecordsToShelf(getState().record.recommendedShelfIndex, records)
//     );
//   };
// }

function externalSourceLoaded(name, shelfIndex, records, extra = {}) {
  return function(dispatch) {
    dispatch({
      type: EXTERNAL_SOURCE_LOADED,
      name
    });
    //dispatch(clearShelf(shelfIndex));
    dispatch(addRecordsToShelf(shelfIndex, records, false, extra));
  };
}

function cleanForUrl(word) {
  return encodeURIComponent(word);
  word = word.replace(" ", "+");
  word = word.replace("(");
  return word;
}
//
// function paramForRecords(records) {
//   return _.join(
//     records.map(r => {
//       const name = cleanForUrl(r.attributes.name);
//       const artistName = cleanForUrl(r.attributes.artistName);
//       return `${name}|${artistName}`;
//     }),
//     ","
//   );
// }

export function loadDiscographyForRecord(record, num) {
  return constant(Constants.discographySource) === MUSICBRAINZ
    ? loadDiscographyForRecordFromMusicBrainz(record, num)
    : loadDiscographyForRecordFromAppleMusic(record, num);
}

export function loadDiscographyForRecordFromMusicBrainz(record, num) {
  return async function(dispatch, getState) {
    dispatch({
      type: LOADING_EXTERNAL_SOURCE,
      name: EXTERNAL_SOURCE_DETAILED_INFO_FOR_RECORD,
      record
    });
    record = bestRecord(getState(), record);
    const artistName = record.attributes.artistName;
    const name = record.attributes.name;
    const _url = `${constant(Constants.baseUrl)}/releases`;
    const records = await fetchJson(_url, { artistName, name });
    dispatch({
      type: EXTERNAL_SOURCE_LOADED,
      name: EXTERNAL_SOURCE_DETAILED_INFO_FOR_RECORD,
      record
    });
    dispatch({ type: DETAILED_INFO_LOADED_FOR_RECORD, record, records });
  };
}

export function loadCoverArt(record, musicbrainzId) {
  return async function(dispatch, getState) {
    dispatch({
      type: LOADING_EXTERNAL_SOURCE,
      name: "EXTERNAL_SOURCE_COVER_ART_FOR_RECORD",
      record
    });
    record = bestRecord(getState(), record);
    const _url = `${constant(Constants.baseUrl)}/cover_art`;
    try {
      const json = await fetchJson(
        _url,
        { mbid: musicbrainzId },
        true,
        "get",
        false
      );
      dispatch({ type: "COVER_ART_METADATA_LOADED", record, json });

      dispatch({
        type: EXTERNAL_SOURCE_LOADED,
        name: "EXTERNAL_SOURCE_COVER_ART_FOR_RECORD",
        record
      });
    } catch (err) {
      jlog({ err });
      dispatch({
        type: EXTERNAL_SOURCE_LOADING_FAILED,
        name: "EXTERNAL_SOURCE_COVER_ART_FOR_RECORD",
        record
      });
    }
  };
}

export function loadDiscographyForRecordFromAppleMusic(_record, num) {
  return async function(dispatch, getState) {
    const state = getState();
    const catalogId = await catalogIdForRecord(_record, state);
    //_record = bestRecord(state, _record);
    const result = await fetchWithAuth(
      `/catalog/us/albums/${catalogId}`,
      {},
      false
    );
    //if (result.data || result.data.length < 1) return;
    const record = _.get(result, "data[0]");
    if (!record) return;
    dispatch({
      type: LOADING_EXTERNAL_SOURCE,
      name: EXTERNAL_SOURCE_DISCOGRAPHY_FOR_RECORD,
      record
    });
    const artistId = _.get(record, "relationships.artists.data[0].id");
    if (!artistId) return;
    let records = [];
    await processEndpoint(
      `catalog/us/artists/${artistId}/albums`,
      {},
      _records => {
        if (_records) records = [...records, ..._records];
      }
    );

    dispatch(discographyLoadedForRecord(record, records));
    dispatch({
      type: EXTERNAL_SOURCE_LOADED,
      name: EXTERNAL_SOURCE_DISCOGRAPHY_FOR_RECORD
    });
  };
}

export function discographyLoadedForRecord(record, records) {
  return { type: DISCOGRAPHY_LOADED_FOR_RECORD, record, records };
}

export function removeRecordFromRecordStoryMap(keyRecord, record) {
  return async function(dispatch) {
    dispatch({ type: REMOVE_RECORD_FROM_RECORD_STORY_MAP, keyRecord, record });
  };
}

export function setSelectedRecord(record, layoutRect) {
  return async function(dispatch, getState) {
    if (!record) return;
    // window.history.replaceState(
    //   { page: 0 },
    //   record.attributes &&
    //     `${record.attributes.artistName} - ${record.attributes.name}`,
    //   `/record/${record.id}`
    // );
    // window.history.pushState(
    //   {},
    //   record.attributes &&
    //     `${record.attributes.artistName} - ${record.attributes.name}`,
    //   `/record/${record.id}`
    // );
    // document.title = "shelf.fm - " + record.attributes ?
    //     `${record.attributes.artistName} - ${record.attributes.name}` : ""

    const state = getState();
    if (state.lightweightRecord.selected)
      setTimeout(() => {
        dispatch(scrollYTo(1));
      }, 500);
    if (!record) return;
    dispatch({ type: RECORD_SELECTED, selected: { record, layoutRect } });
    setTimeout(() => {
      dispatch(setPaletteBasedOnRecord(record));
    }, SELECTION_ANIMATION_DURATION * 3);
  };
}

export function clearSelectedRecord() {
  return async function(dispatch, getState) {
    //window.history.replaceState({ page: 0 }, `Shelf.fm`, `/`);
    //window.location.href="/"
    //document.title= "shelf.fm"
    setTimeout(() => {
      dispatch(setNormalPalette());
    }, SELECTION_ANIMATION_DURATION * 3);
    //dispatch(returnToBookmark());
    dispatch({ type: TURN_BIG_RECORD_TO_SIDE, side: 0 });

    dispatch({ type: RECORD_SELECTED, selected: null });
  };
}

export function loadDetailedInfoForRecord(record, num = 20) {
  return async function(dispatch, getState) {
    return;
    const state = getState();
    const {
      storefrontId: countryCode = browserCountryCode()
    } = state.authentication;
    dispatch({
      type: LOADING_EXTERNAL_SOURCE,
      name: EXTERNAL_SOURCE_DETAILED_INFO_FOR_RECORD,
      record
    });
    let { name, artistName, releaseDate, recordLabel } = record.attributes;
    /* label_releases_for_record will :
  - find earliest release for this record on musicbrainz
  - get label for that release
  - get surrounding releases for that label at that time (releaseDate)
 */
    /*
    http://localhost:3001/releases/label_releases_for_record
    ?name=Mezzanine&artistName=Massive+Attack&releaseDate=2016-05-27
    &countryCode=us&num=20&format=json
     */
    const params = {
      name: cleanName(name),
      artistName,
      releaseDate,
      countryCode,
      num,
      format: "json"
    };
    if (recordLabel) params.labelName = recordLabel;

    const url = `${constant(Constants.baseUrl)}/release_detailed_info`;
    const detailedInfo = await fetchJson(url, params);
    if (!detailedInfo) {
      dispatch({
        type: EXTERNAL_SOURCE_LOADING_FAILED,
        name: EXTERNAL_SOURCE_DETAILED_INFO_FOR_RECORD
      });
      return;
    }
    const musicbrainzId = _.get(
      detailedInfo,
      "earliest_release.musicbrainz_id"
    );
    //const otherReleases = results.all_releases;
    // //dispatch(loadedOtherReleases(record, otherReleases));
    // try {
    //   musicbrainzId && dispatch(loadCoverArt(record, musicbrainzId));
    // } catch (err) {}
    dispatch({
      type: EXTERNAL_SOURCE_LOADED,
      name: EXTERNAL_SOURCE_DETAILED_INFO_FOR_RECORD,
      record
    });
    dispatch({ type: DETAILED_INFO_LOADED_FOR_RECORD, record, detailedInfo });
  };
}

export function loadLabelRecords(callback) {
  return async function(dispatch, getState) {
    try {
      return;
      const state = getState();
      const {
        storefrontId: countryCode = browserCountryCode()
      } = state.authentication;
      dispatch({
        type: LOADING_EXTERNAL_SOURCE,
        name: EXTERNAL_SOURCE_LABEL_RECORDS
      });
      const _url = `${constant(
        Constants.baseUrl
      )}/latest_releases_for_artists_labels?country_code=${countryCode}`;
      const shelfIndex = state.authentication.loggedIn
        ? state.record.recentsShelfIndex
        : state.record.tryShelfIndex;

      const records = state.record.shelves[shelfIndex].records;
      const howManyToSend = srcs().labelRecordsToSend;

      const catalogRecords = _.slice(records, 0, howManyToSend)
        .map(record => bestRecord(state, record))
        .filter(r => state.record.ratingsMap[r.catalogAlbumId] !== -1) // dont recommend based on deleted
        .filter(r => !excludedLabels.includes(r.attributes.recordLabel));

      // const uniqueLabelRecords = _.uniqWith(
      //   catalogRecords,
      //   (a, b) =>
      //     if (_.get(a, "attributes.recordLabel") === undefined)
      //
      //     a.attributes.recordLabel === _.get(b, "attributes.recordLabel")
      // );
      console.log(
        "uniqueLabelRecords",
        _.slice(catalogRecords, 0, howManyToSend).map(r => slugForRecord(r))
      );
      const trimmedRecords = catalogRecords.map(r => {
        const {
          artistName,
          trackCount,
          releaseDate,
          name,
          recordLabel
        } = r.attributes;
        return {
          id: r.id,
          href: r.href,
          attributes: {
            artistName,
            trackCount,
            releaseDate,
            name,
            recordLabel
          }
        };
      });

      const response = await fetch(_url, {
        method: "post",
        body: JSON.stringify(trimmedRecords)
      });
      const responseRecords = await response.json();
      if (responseRecords && responseRecords.length > 0) {
        const processedRecords = _.sortBy(responseRecords.filter(r => r), r =>
          _.get(r, "attributes.releaseDate")
        ).filter(r => !excludedLabels.includes(r.attributes.recordLabel));
        //.reverse();
        const dedupedRecords = dedupeRecords(processedRecords);
        dispatch(
          externalSourceLoaded(
            EXTERNAL_SOURCE_LABEL_RECORDS,
            getState().record.labelRecordsShelfIndex,
            _.slice(dedupedRecords, 0, 5000),
            { catalogSource: CATALOG_ID_LABEL_NEW_RELEASES }
          )
        );
      }
      callback && callback();
    } catch (err) {
      callback && callback();
    }
  };
}

export function loadSpinitronTop50() {
  return function(dispatch, getState) {
    dispatch({
      type: LOADING_EXTERNAL_SOURCE,
      name: EXTERNAL_SOURCE_SPINITRON_TOP_50
    });
    dispatch(clearShelf(getState().record.scratchShelfIndex));
    setImmediate(() => {
      const url = "https://s3.amazonaws.com/shelf.fm/spinitron.json";
      fetch(url)
        .then(function(response) {
          return response.json();
        })
        .then(json => {
          const records = json.results.albums.data;
          if (records && records.length > 0)
            dispatch(
              externalSourceLoaded(
                EXTERNAL_SOURCE_SPINITRON_TOP_50,
                getState().record.scratchShelfIndex,
                records,
                { catalogSource: CATALOG_ID_SPINITRON }
              )
            );
        })
        .catch(error => {
          dispatch({
            type: EXTERNAL_SOURCE_LOADING_FAILED,
            name: EXTERNAL_SOURCE_SPINITRON_TOP_50,
            error
          });
        });
    });
  };
}

export function loadAppleMusicRecommendedAlbums() {
  return function(dispatch, getState) {
    console.log("loadAppleMusicRecommendedAlbums a")
    fetchWithAuth("me/recommendations").then(response => {
      console.log("loadAppleMusicRecommendedAlbums b")
      dispatch(parseOutNewReleases(response));
      //dispatch(parseOutRecommendations(response));
    });
  };
}

export function loadTracksIfNeeded(record) {
  let tracks = [];
  return async function(dispatch, getState) {
    if (isPlaylist(record)) {
      await processEndpoint(
        `me/library/playlists/${record.id}/tracks`,
        {},
        _tracks => {
          if (_tracks) tracks = [...tracks, ..._tracks];
        },
        10000
      );
      dispatch({ type: TRACKS_LOADED_FOR_RECORD, record, tracks });
    } else {
      //if (getState().record.recordIdToTracksMap[record.id]) return;
      const href =
        record.href ||
        `/v1/catalog/${browserCountryCode()}/albums/${record.id}`;
      const album = await fetchObjectWithHref(href);
      tracks = _.get(album, "relationships.tracks.data");
      dispatch({ type: TRACKS_LOADED_FOR_RECORD, record, tracks });
    }
  };
}

export function loadRecordIfNeeded(record, force = false, onLoad) {
  return async function(dispatch, getState) {
    const state = getState();
    const { slugToRecordMap } = state.record;
    if (!record) {
      onLoad && onLoad(record);
      return null;
    }

    if (record.trackId) {
      const catalogTrack = await fetchWithAuth(
        `/v1/catalog/us/songs/${record.trackId}`,
        {
          include: "albums"
        }
      );
      let album = _.get(
        catalogTrack,
        "data[0].relationships.albums.data[0]",
        null
      );
      if (album) {
        album.attributes = { ...album.attributes, ...record.attributes };
        album.extra = { ...album.extra, ...record.extra };
        dispatch(addRecordsToMap([album]));
      }
      onLoad && onLoad(album);
      return;
    }
    let foundInAM = false;
    if (
      ((!record.id || force) &&
        _.get(record, "attributes.name") &&
        _.get(record, "attributes.artistName")) ||
      record.type === "library-albums"
    ) {
      let catalogAlbum =
        slugToRecordMap[
          slugForArtistNameAndAlbumName(
            record.attributes.artistName,
            record.attributes.name
          )
        ] ||
        (await getCatalogAlbumWithArtistNameAndAlbumName(
          record.attributes.artistName,
          record.attributes.name
        ));
      if (catalogAlbum) {
        foundInAM = true;
        catalogAlbum.attributes = {
          ...catalogAlbum.attributes,
          ...record.attributes
        };
        dispatch({
          type: RECORD_DETAILS_LOADED_BASED_ON_SLUG,
          record: catalogAlbum
        });
        dispatch(addRecordsToMap([catalogAlbum]));
      }
      onLoad && onLoad(catalogAlbum || record);
    } else {
      if ((!record.attributes && record.href) || force) {
        foundInAM = true;
        const href = record.href || `/v1/catalog/us/albums/${record.id}`;
        fetchWithAuth(href, {}, false).then(response => {
          const r = _.get(response, "data[0]");
          if (r) {
            dispatch(addRecordsToMap([r]));
          }
          onLoad && onLoad(r);
        });
      } else {
        onLoad && onLoad(record);
      }
    }
    if (!foundInAM && _.get(record, "attributes.musicbrainzId")) {
      dispatch({
        type: ADD_RECORDS_TO_MUSICBRAINZ_ONLY_MAP,
        records: [record]
      });
    }
  };
}

export function clearUserData() {
  return function(dispatch) {
    dispatch({ type: CLEAR_USER_DATA });
  };
}

// Wrap the async function and dispatch an error if an error occurred
function wrap(fn) {
  return function(dispatch) {
    fn(dispatch).catch(error => dispatch({ type: "ERROR", error }));
  };
}

/*
  "record": {
    "id": "l.gwpyojs",
    "type": "library-albums",
    "href": "/v1/me/library/albums/l.gwpyojs",
    "attributes": {
      "name": "Europa - EP",
      "playParams": {
        "id": "l.gwpyojs",
        "kind": "album",
        "isLibrary": true
      },
      "artistName": "Diplo",
      "artwork": {
        "width": 1200,
        "height": 1200,
        "url": "https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/35/a7/37/35a737c9-b050-8cd3-b18d-14f75437899e/873.jpg/{w}x{h}bb.jpeg"
      },
      "trackCount": 6
    }
  },
 */

/*
{
  "record": {
    "id": "709397994",
    "type": "albums",
    "href": "/v1/catalog/us/albums/709397994",
    "attributes": {
      "artwork": {
        "width": 1417,
        "height": 1417,
        "url": "https://is3-ssl.mzstatic.com/image/thumb/Music6/v4/26/ff/56/26ff56cb-460c-367a-a3cc-ca01b1b59af4/5099969756153_1417x1417_300dpi.jpg/{w}x{h}bb.jpeg",
        "bgColor": "ffffff",
        "textColor1": "030303",
        "textColor2": "2f2f2f",
        "textColor3": "353535",
        "textColor4": "595959"
      },
      "artistName": "Röyksopp",
      "isSingle": false,
      "url": "https://music.apple.com/us/album/junior/709397994",
      "isComplete": false,
      "genreNames": [
        "Dance",
        "Music",
        "Electronic",
        "Rock",
        "Jazz",
        "Bop",
        "Downtempo"
      ],
      "trackCount": 12,
      "isMasteredForItunes": false,
      "releaseDate": "2009-03-20",
      "name": "Junior",
      "recordLabel": "EMI France",
      "copyright": "℗  2009 Parlophone Music France"
    }
  },
  "time": "1560012881.302"
}
 */
