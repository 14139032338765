import { jlog } from "../helpers";
import {developerToken} from "../developer-token";
import { loadUserData } from "./record-actions";
import { CLEAR_USER_DATA } from "../reducers/record-reducer";
import { dlog } from "./debug-actions";
import {
  initializePalette,
  initializeUIVariables,
  setNormalPalette,
  setPalette,
  setPickedItem
} from "./ui-actions";
import { postToSlack } from "../helpers";
import { reportEvent } from "../analytics";
import _ from "lodash";
import { initializeOrbit } from "../orbit/orbit-coordinator";
import {
  recentlyPlayedShelfItem,
  recentsShelfItem
} from "../components/ShelfPicker/shelf-picker";
import { loggedInPalette } from "../reducers/ui-reducer";
import {flash} from '../actions/ui-actions'
export const AUTHENTICATE = "AUTHENTICATE";
export const UNAUTHENTICATE = "UNAUTHENTICATE";

export function autologin(cookies) {
  return async function(dispatch) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    jlog({search,params})
    let musicUserToken = params.get("musicUserToken");
    if (!musicUserToken || musicUserToken.length < 10)
      musicUserToken = cookies.get("musicUserToken");

    dispatch(initializePalette());
    dispatch(initializeUIVariables());
    if (musicUserToken) {
      reportEvent("successful authentication");
      dispatch(
        authenticate(cookies, musicUserToken, () => dispatch => {
          initializeOrbit(developerToken, musicUserToken).then(() => {
            console.log("here a")
            dispatch(loadUserData());
          });
        })
      );
    } else {
      dispatch(unauthenticate(cookies, null, false));
    }
  };
}

function authenticatedSetup(cookies, musicUserToken, onAuthenticated) {
  return function(dispatch, getState) {
    const storefrontId = window.MusicKitInstance.storefrontId; //eg 'us'
    dispatch({ type: AUTHENTICATE, storefrontId });

    global.userToken = musicUserToken;
    jlog({
      musicUserToken: musicUserToken ? musicUserToken.substring(0, 24) : "nil"
    });

    // set cookie
    cookies.set("musicUserToken", musicUserToken, {
      path: "/",
      maxAge: 60 * 60 * 24 * 365
    });
    dispatch(setPalette(loggedInPalette));
    if (!getState().ui.pickedItem)
      dispatch(setPickedItem(recentlyPlayedShelfItem));
    onAuthenticated && dispatch(onAuthenticated());
  };
}

export function setup(cookies, musicUserToken, onAuthenticated) {
  jlog({ musicUserToken });
  return async function(dispatch, getState) {
    try {
      window.MusicKitInstance = await window.MusicKit.configure({
        bitrate: window.MusicKit.PlaybackBitrate.HIGH,
        developerToken,
        app: {
          name: "celery",
          build: "1"
        }
      });
    } catch (e) {
      console.log(e.message);
      window.alert("Error initializing apple music: "+e.message+" "+developerToken);
      return;
    }

    // console.log(window.MusicKitInstance);
    // dispatch(dlog({ isAuthorized: window.MusicKitInstance.isAuthorized }));
    // dispatch(dlog({ isRestricted: window.MusicKitInstance.isRestricted }));
    // dispatch(dlog({ storeFrontId: window.MusicKitInstance.storeFrontId }));
    // dispatch(dlog({ previewOnly: window.MusicKitInstance.previewOnly }));
    // dispatch(dlog({ version: window.MusicKitInstance.version }));
    // dispatch(dlog({ _maxBitrate: window.MusicKitInstance._maxBitrate }));
    // dispatch(dlog({ _bitRate: window.MusicKitInstance._bitRate }));
    // dispatch(dlog({ bitRate: window.MusicKitInstance.bitRate }));
    if (musicUserToken) {
      dispatch(authenticatedSetup(cookies, musicUserToken, onAuthenticated));
    } else {
      window.MusicKitInstance.authorize().then(musicUserToken => {
        dispatch(authenticatedSetup(cookies, musicUserToken, onAuthenticated));
      });
    }
  };
}

export function authenticate(cookies, musicUserToken, onAuthenticated) {

  return function(dispatch) {
    if (window.MusicKit !== undefined) {
      dispatch(setup(cookies, musicUserToken, onAuthenticated));
    } else {
      document.addEventListener("musickitloaded", () => {
        dispatch(setup(cookies, musicUserToken, onAuthenticated));
      });
    }
  };
}

export function unauthenticate(cookies, reload = true) {
  return function(dispatch) {
    dispatch({ type: UNAUTHENTICATE });
    dispatch({ type: CLEAR_USER_DATA });
    cookies.remove("musicUserToken");
    if (reload) window.location.reload();
    global.userToken = null;
  };
}
