import MemorySource from "@orbit/memory";
import IndexedDBSource from "@orbit/indexeddb";
import JSONAPISource from "@orbit/jsonapi";
import Coordinator, { RequestStrategy, SyncStrategy } from "@orbit/coordinator";
import { schema } from "./orbit-schema";

const localStorage = new IndexedDBSource({
  schema,
  name: "localStorage",
  namespace: "shelf"
});

const memory = new MemorySource({ schema });
const musicKitRemote = new JSONAPISource({
  schema,
  name: "musicKit",
  namespace: "library",
  host: "https://api.music.apple.com/v1/me"
});

async function buildCoordinator(developerToken, musicUserToken) {
  musicKitRemote.requestProcessor.defaultFetchSettings = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + developerToken,
      "Cache-Control": "max-age=0",
      "User-Agent":
        "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.138 Safari/537.36 shelf.fm",
      "Music-User-Token": musicUserToken
    }
  };

  const transform = await localStorage.pull(q => q.findRecords("album"));
  await memory.sync(transform);

  const coordinator = new Coordinator({
    sources: [memory, localStorage, musicKitRemote]
  });

  // coordinator.addStrategy(
  //   new SyncStrategy({
  //     source: "memory",
  //     target: "localStorage",
  //     blocking: false
  //   })
  // );

  coordinator.addStrategy(
    new RequestStrategy({
      source: "memory",
      on: "beforeQuery",
      target: "musicKit",
      action: "query",
      blocking: false
    })
  );

  coordinator.addStrategy(
    new RequestStrategy({
      source: "memory",
      on: "beforeUpdate",

      target: "musicKit",
      action: "update",

      blocking: false
    })
  );

  coordinator.addStrategy(
    new SyncStrategy({
      source: "musicKit",
      target: "memory",
      blocking: false
    })
  );
  return coordinator;
}

const orbitStore = memory;

export default orbitStore;
//export musicKitRemote;

export async function initializeOrbit(developerToken, musicUserToken) {
  const coordinator = await buildCoordinator(developerToken, musicUserToken);
  return coordinator.activate();
}
