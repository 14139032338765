import React from "react";
import connect from "react-redux/es/connect/connect";
import Record from "../Record/record";
import _ from "lodash";
import {
  hardRefreshShelf,
  loadPlaylists,
  setShelfSortOption
} from "../../actions/record-actions";
import styles from "./shelf.module.scss";
import {
  areRecordsTheSame,
  debounce,
  jlog,
  slugForRecord
} from "../../helpers";
import { SET_PREVIEW_READY } from "../../reducers/ui-reducer";
import { flag, Flags } from "../../helpers/flags";
import { isContainedInIosNativeApp } from "../../helpers/native-ios-mk-implementation";
import { isInGenre } from "../../util/genres";
import Playlist from "../Playlist/playlist";
import { stopPreviewingRecord } from "../../actions/playback-actions";
import VisibilitySensor from "../../helpers/visibility-sensor";
import { JsonBlitter } from "../Controls/JsonBlitter/json-blitter";
import orbitStore from "../../orbit/orbit-coordinator";
import { withData } from "react-orbitjs";
import { FunctionMap } from "../../helpers/function-map";

window.onresize = () => {};

const PAGE_SIZE = 100;

export class ShelfComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numRecordsLoaded: 0,
      numImagesLoaded: 0,
      limit: 100
      // playlists:[]
    };
    // //props.orbitQuery &&
    //     orbitStore.query(q=>q.findRecords("playlist"))
    //     .then((playlists)=>this.setState({playlists}));
  }

  componentDidMount() {
    this.props.loadRecordsExternally(this.props.index);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.index !== this.props.index ||
      this.props.key !== prevProps.key
    ) {
      this.setState({ limit: PAGE_SIZE });
      this.props.loadRecordsExternally(this.props.index);
    }
    if (
      prevProps.shelf &&
      prevProps.shelf.layoutCode !== this.props.shelf.layoutCode
    ) {
      this.forceUpdate();
    }
  }

  render() {
    const {
      height,
      recordProps,
      RecordComponent = Record,
      //RecordComponent = ReeeaallySimpleRecord,
      recordClass,
      recordStyle,
      showName,
      showArtistName,
      cover,
      targetRecordWidth,
      klass = "default",
      shelf,
      allowRefresh = false,
      excludeRecords = [],
      showDeleted = false,
      records,
      orbitRecords,
      groupContainerStyle,
      setShelfSortOption,
      groupRecordClass,
      index: shelfIndex,
      sortOptions,
      filterPredicates = null
    } = this.props;
    // const {playlists} = this.state
    // jlog({playlists})
    const layoutCode = shelf && shelf.layoutCode;

    const layoutKlass = layoutCode ? `${klass}-${layoutCode}` : klass;
    // if (!records || records.length === 0)
    //   return <div className={styles.loading}>loading ...</div>;
    let filteredRecords = _.slice(
      this.props.orbitQueryFunctionMapKey ? orbitRecords : records,
      0,
      5000
    ).filter(record=>record.attributes.index==null||record.attributes.index>=0); // hack to filter out localstorage records
    if (filterPredicates)
      filteredRecords = records.filter(r =>
        filterPredicates.reduce((acc, filterPredicate) => {
          if (!acc) return acc;
          let accepted = true;

          if (filterPredicate["genre"]) {
            accepted = isInGenre(
              filterPredicate["genre"],
              _.get(r, "attributes.genreNames[0]")
            );
          } else if (filterPredicate["minTracks"]) {
            accepted = r.attributes.trackCount > filterPredicate["minTracks"];
          }
          return accepted;
        }, true)
      );

    const unexcludedRecords = filteredRecords.filter(record => {
      return !excludeRecords.reduce((acc, excludedRecord) => {
        const match = areRecordsTheSame(record, excludedRecord);
        if (match) {
        }
        return acc || match;
      }, false);
    });
    let sortOption = sortOptions
      ? sortOptions[(shelf || this.props).sortOptionIndex || 0] || {
          sortRecordsBy: "index"
        }
      : {};
    // if (Object.keys(sortOption).length === 0)
    //   sortOption = {
    //     sortRecordsBy: "index"
    //   };
    let containerStyle = sortOption.inlineLabels
      ? { ...this.props.containerStyle, rowGap: "0px" }
      : {
          ...this.props.containerStyle,
          rowGap: "0px",
          columnGap: this.props.columnGap
        };

    let sortedRecords = unexcludedRecords;
    let groups = null;
    let groupLabels = null;
    let grouped = false;
    if (sortOption) {
      if (sortOption.sortRecordsBy) {
        const sortFunction =
          typeof sortOption.sortRecordsBy === "function"
            ? sortOption.sortRecordsBy
            : record => _.get(record, sortOption.sortRecordsBy);

        sortedRecords = _.sortBy(unexcludedRecords, sortFunction);
        if (sortOption.reverse) sortedRecords.reverse();
      }
      if (sortOption.groupRecordsBy) {
        grouped = true;
        groups = _.groupBy(sortedRecords, r =>
          _.get(r, sortOption.groupRecordsBy)
        );
        groupLabels = groups && Object.keys(groups);
      }
    }
    const showHeader = true;
    // (records.length > 0 && this.state.numRecordsLoaded > 0) ||
    // (groups && groups.length > 0);
    let sortedLimitedRecords = _.slice(sortedRecords, 0, this.state.limit);
    const leftChild =
      this.props.children && this.props.children.length > 1
        ? _.get(this.props, "children[0]")
        : this.props.children;
    const rightChild = _.get(this.props, "children[1]");

    return (
      <div className={styles[layoutKlass]}>
        {/*<JsonBlitter json={sortedLimitedRecords} initiallyClosed={true}/>*/}

        {showHeader && (
          <>
            <div className={styles.header}>
              <div className={styles.inner}>
                <div className={styles.left}>{leftChild}</div>
                <div className={styles.right}>{rightChild}</div>
              </div>
            </div>
          </>
        )}
        {grouped ? (
          <div className={styles["groups"]}>
            {groupLabels.map(l => {
              const layoutRecordClass = layoutCode
                ? `${recordClass}-${layoutCode}`
                : recordClass;
              return (
                <div className={styles["group"]}>
                  <div className={styles["label"]}>{l}</div>
                  <div className={styles["recordGroup"]}>
                    <Shelf
                      showDeleted={showDeleted}
                      recordClass={groupRecordClass || layoutRecordClass}
                      containerStyle={groupContainerStyle}
                      records={groups[l]}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles.records} style={containerStyle}>
            {sortedLimitedRecords.map((record, i) => {
              const previousRecord = i > 0 ? sortedLimitedRecords[i - 1] : null;
              const nextRecord =
                sortedLimitedRecords.length > i - 1
                  ? sortedLimitedRecords[i + 1]
                  : null;
              const isNewArtist =
                previousRecord === null ||
                (previousRecord.attributes.artistName !==
                  record.attributes.artistName &&
                  (nextRecord &&
                    nextRecord.attributes.artistName ===
                      record.attributes.artistName));
              const _recordClass =
                sortOption.inlineLabels && isNewArtist
                  ? recordClass + "-artist-overhead"
                  : recordClass;

              const layoutRecordClass = layoutCode
                ? `${recordClass}-${layoutCode}`
                : recordClass;
              return (
                <Record
                  layoutCode={layoutCode}
                  onLoad={this.onRecordLoad}
                  onImageLoad={this.onImageLoad}
                  {...recordProps}
                  showDeleted={showDeleted}
                  // height={height}
                  // width={targetRecordWidth}
                  key={`record-${record.id}`}
                  klass={layoutRecordClass}
                  style={recordStyle}
                  showArtistName={showArtistName}
                  showName={showName}
                  cover={cover}
                  record={record}
                />
              );
            })}
          </div>
        )}
        <div className={styles["footer"]}>
          {allowRefresh && !isContainedInIosNativeApp() && records.length > 0 && (
            <button
              className={styles.refreshButton}
              onClick={this.props.refresh}
            >
              refresh
            </button>
          )}
        </div>
        <VisibilitySensor
          active={true}
          // offset={{ bottom: layoutCode === "s" ? 200 : 1000 }}
          onChange={visible =>
            visible &&
            console.log("visible") && this.setState(state => ({ limit: state.limit + PAGE_SIZE }))
          }
        >
          <div className={styles.loading}>
            {sortedLimitedRecords &&
              sortedLimitedRecords.length > this.state.limit &&
              "loading..."}
          </div>
        </VisibilitySensor>
      </div>
    );
  }

  // onMouseMove = () => {
  //   debounce(
  //     "mouseMove",
  //     () => {
  //       if (flag(Flags.previewingActive)) {
  //         this.props.setPreviewReady(true);
  //         //this.props.stopPreviewingRecord();
  //       }
  //     },
  //     1000
  //   );
  // };

  nextOption = () => {
    let {
      setShelfSortOption,
      shelf: { sortOptionIndex, sortOptions, index }
    } = this.props;
    sortOptionIndex += 1;
    if (sortOptionIndex > sortOptions.length - 1) sortOptionIndex = 0;
    setShelfSortOption(index, sortOptionIndex);
  };

  onRecordLoad = () => {
    this.setState({ numRecordsLoaded: this.state.numRecordsLoaded + 1 });
  };
  onImageLoad = () => {
    this.setState({ numImagesLoaded: this.state.numImagesLoaded + 1 });
  };
}

const mapRecordsToProps = props => {
  const orbitRecords = FunctionMap[props.orbitQueryFunctionMapKey]
  return props.orbitQueryFunctionMapKey
    ? { orbitRecords  }
    : {};
};

const mapStateToProps = (state, ownProps) => {
  const { index, records = [] } = ownProps;
  let ___records = [];
  const _records =
    index !== undefined ? state.record.shelves[index].records : records;
  const __records =
    ownProps.limit || ownProps.limit === 0
      ? _.slice(_records, 0, ownProps.limit)
      : _records;
  ___records = __records
    ? __records.filter(
        r => state.record.ratingsMap[slugForRecord(r, true)] !== -1
      )
    : [];
  // const playlists = state.record.shelves[index]
  //   ? state.record.shelves[index].playlists || []
  //   : [];
  return {
    selectedRecord: _.get(state.record, "selected.record"),
    palette: state.ui.palette,
    shelf: {
      ...state.record.shelves[ownProps.index],
      layoutCode: ownProps.layoutCode
    },
    records: [...(___records || [])]
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadRecordsExternally: id => {
      if (ownProps.loadRecordsFunctionMapKey) {
        FunctionMap[ownProps.loadRecordsFunctionMapKey](dispatch, id);
      }
      if (ownProps.loadRecords) {
        ownProps.loadRecords(dispatch, id);
      }
    },
    stopPreviewingRecord: record => dispatch(stopPreviewingRecord(record)),

    setPreviewReady: b => {
      dispatch({ type: SET_PREVIEW_READY, b });
    },
    setShelfSortOption: (shelfIndex, sortOptionIndex) =>
      dispatch(setShelfSortOption(shelfIndex, sortOptionIndex)),
    refresh: () => {
      dispatch(hardRefreshShelf(ownProps.index));
    }
  };
};
const Shelf = withData(mapRecordsToProps)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShelfComponent)
);
export default Shelf;
